import { getPlayerStackWithContrib } from "@/features/pokerTable";
import {
  isActionWithData,
  isDiscardAction,
  isFoldAction,
  isSingleUpCardAction,
  isTerminatedAction,
} from "@/features/sequence/action";
import { PlayerWithAdditionalInfo } from "@/entities/Player";
import { SeqActionStateEnum } from "@/enums/sequenceEnum";
import { PlayerCardTypeEnum, PlayerStateEnum } from "@/enums/playerEnum";
import { PokerActionsEnum } from "@/poker-query-lib/enums/poker-actions.enum";
import { getDefaultUpCard } from "@/features/playerCards";
import { getNumFinishedRounds } from "@/features/sequence/utils";
import { useGameStore } from "@/stores/game";
import { sortArrFromIdx } from "@/utils/sortArrFromIdx";
import type { Player } from "@/entities/Player";
import type { SeqActionAny, SeqActionSingleUpCard } from "@/types/seqAction";
import type { InteractivePlayerStateParams } from "@/types/player";

const isPlayerFromSeqFolded = (
  sequence: SeqActionAny[],
  seqPointer: number,
  playerIdx: number
) => {
  const playerActions = sequence.filter((action, idx) => {
    if (isActionWithData(action)) {
      return action.stateInfo.playerIndex === playerIdx && idx < seqPointer;
    }
    return false;
  });
  return playerActions.some((action) => isFoldAction(action));
};

const isCallEnd = (actionValue: string, actionState: SeqActionStateEnum) => {
  return (
    actionState === SeqActionStateEnum.performed &&
    [`${PokerActionsEnum.CALL}/`, `${PokerActionsEnum.CALL}/$`].includes(
      actionValue
    )
  );
};

const isActivePlayer = (activeAction: SeqActionAny, playerIndex: number) => {
  return (
    isActionWithData(activeAction) &&
    playerIndex === activeAction.stateInfo.playerIndex &&
    !isTerminatedAction(activeAction)
  );
};
const getInteractivePlayerState = (data: InteractivePlayerStateParams) => {
  const { sequence, seqPointer, playerState, player, playerNames, gameConfig } =
    data;
  const activeAction = sequence[seqPointer];
  if (
    playerState === PlayerStateEnum.folded ||
    !gameConfig ||
    !isActionWithData(activeAction)
  ) {
    return false;
  }
  const firstPlayerIdxInRound =
    gameConfig.firstPlayer[getNumFinishedRounds(seqPointer, sequence)];
  const sortedNamesByRound = sortArrFromIdx(playerNames, firstPlayerIdxInRound);
  const currentPlayerIdx = sortedNamesByRound.indexOf(player.name);
  const activePlayerName = playerNames[activeAction.stateInfo.playerIndex];
  const activePlayerIdx = sortedNamesByRound.indexOf(activePlayerName);
  return currentPlayerIdx > activePlayerIdx;
};

const getFirstActionAfterFourthUpCardAction = (arr: SeqActionAny[]) => {
  let singleUpCardCount = 0;

  for (let i = 0; i < arr.length; i++) {
    if (isSingleUpCardAction(arr[i])) {
      singleUpCardCount++;
    }
    if (singleUpCardCount === 4 && i + 1 < arr.length) {
      return arr[i + 1];
    }
  }

  return null;
};

const getPlayerState = (
  sequence: SeqActionAny[],
  seqPointer: number,
  activeAction: SeqActionAny,
  playerIndex: number
) => {
  const isFoldedState = isPlayerFromSeqFolded(
    sequence,
    seqPointer,
    playerIndex
  );
  const isActiveState = isActivePlayer(activeAction, playerIndex);
  if (isFoldedState) {
    return PlayerStateEnum.folded;
  } else if (isActiveState) {
    return PlayerStateEnum.active;
  }
  return PlayerStateEnum.regular;
};

const getActionValue = (
  sequence: SeqActionAny[],
  activeAction: SeqActionAny
) => {
  const findLastActionWithValue = () => {
    for (let i = sequence.length - 1; i >= 0; i--) {
      if ("chosenAction" in sequence[i]) {
        return sequence[i];
      }
    }
    return null;
  };
  const action = isTerminatedAction(activeAction)
    ? findLastActionWithValue()
    : activeAction;
  return action && isActionWithData(action) ? action.chosenAction : "";
};

const getStackWithContrib = (
  sequence: SeqActionAny[],
  activeAction: SeqActionAny,
  playerIndex: number
) => {
  const actionValue = getActionValue(sequence, activeAction);
  const stateInfo =
    isTerminatedAction(activeAction) || isActionWithData(activeAction)
      ? activeAction.stateInfo
      : undefined;
  const { stack, contrib } = getPlayerStackWithContrib(
    playerIndex,
    stateInfo,
    isCallEnd(actionValue, activeAction.state)
  );

  return {
    contrib,
    stack,
  };
};
const getNormalizedDrawPlayerList = (
  playerList: Player[],
  sequence: SeqActionAny[],
  seqPointer: number
) => {
  const getPlayerNumDiscardedCardsInRound = (playerIdx: number) => {
    const getIdxOfFirstDiscardActionInRound = (arr: SeqActionAny[]) => {
      for (let i = arr.length - 1; i > 0; i--) {
        if (isDiscardAction(arr[i]) && !isDiscardAction(arr[i - 1])) {
          return i;
        }
      }
      return -1;
    };
    const prevAction = sequence[seqPointer - 1];
    if (!prevAction || !isDiscardAction(prevAction)) {
      return 0;
    }
    const discardActionIdx = getIdxOfFirstDiscardActionInRound(
      sequence.slice(0, seqPointer)
    );
    for (let i = seqPointer - 1; i >= discardActionIdx; i--) {
      const action = sequence[i];
      if (
        isDiscardAction(action) &&
        action.stateInfo.playerIndex === playerIdx
      ) {
        return Number(action.chosenAction.replace(/\D/g, ""));
      }
    }
    return 0;
  };
  return playerList.map((player) => {
    const numDiscardedCards = getPlayerNumDiscardedCardsInRound(
      player.playerIndex
    );
    return {
      ...player,
      cardList: player.cardList.map((card, idx) => ({
        ...card,
        type:
          idx < numDiscardedCards
            ? PlayerCardTypeEnum.discard
            : PlayerCardTypeEnum.regular,
      })),
    };
  });
};
const getNormalizedStudPlayerList = (
  playerList: Player[],
  sequence: SeqActionAny[],
  seqPointer: number,
  playerNames: string[]
) => {
  let action: SeqActionSingleUpCard | null = null; // first upCard action before active action
  for (let i = seqPointer - 1; i >= 0; i--) {
    const seqAction = sequence[i];
    if (isSingleUpCardAction(seqAction)) {
      action = seqAction;
      break;
    }
  }
  if (action === null) {
    return playerList;
  }
  const actionUpCards = action.upCards;
  const getCardList = (player: Player) => {
    const cardList = player.cardList
      .slice(0, 2)
      .concat(actionUpCards[player.name].map(getDefaultUpCard));
    if (action !== null && action.heroCard) {
      const getIsBackState = () => {
        const firstActionAfterFourthUpCardAction =
          getFirstActionAfterFourthUpCardAction(sequence);
        if (
          firstActionAfterFourthUpCardAction === null ||
          !isActionWithData(firstActionAfterFourthUpCardAction)
        ) {
          return false;
        }
        return (
          playerNames[
            firstActionAfterFourthUpCardAction.stateInfo.playerIndex
          ] !== player.name
        );
      };
      cardList.push({
        isBack: getIsBackState(),
        type: PlayerCardTypeEnum.regular,
        value: action.heroCard,
      });
    }
    return cardList;
  };
  return playerList.map((player) => {
    return {
      ...player,
      cardList: getCardList(player),
    };
  });
};

export const getPlayersWithAdditionalInfo = (
  playerList: Player[] | undefined,
  sequence: SeqActionAny[],
  seqPointer: number
) => {
  if (!playerList) {
    return [];
  }
  const gameStore = useGameStore();
  const activeAction = sequence[seqPointer];
  let normalizedPlayers = playerList;
  if (gameStore.isCurrentGameDraw) {
    normalizedPlayers = getNormalizedDrawPlayerList(
      playerList,
      sequence,
      seqPointer
    );
  }
  if (gameStore.isCurrentGameStud) {
    normalizedPlayers = getNormalizedStudPlayerList(
      playerList,
      sequence,
      seqPointer,
      gameStore.playerNames
    );
  }

  return normalizedPlayers.map((player) => {
    const { playerIndex } = player;
    const playerState = getPlayerState(
      sequence,
      seqPointer,
      activeAction,
      playerIndex
    );
    const stackWithContrib = getStackWithContrib(
      sequence,
      activeAction,
      playerIndex
    );
    const isInteractive = getInteractivePlayerState({
      gameConfig: gameStore.configByConnectionName,
      player,
      playerNames: gameStore.playerNames,
      playerState,
      seqPointer,
      sequence,
    });

    return new PlayerWithAdditionalInfo(
      player,
      stackWithContrib,
      playerState,
      isInteractive
    );
  });
};
