import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "settings" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "dropdown__list" }
const _hoisted_4 = { class: "dropdown__list-item" }
const _hoisted_5 = { class: "dropdown__list-item-text" }
const _hoisted_6 = { class: "dropdown__list-item" }
const _hoisted_7 = { class: "dropdown__list-item-text" }
const _hoisted_8 = { class: "dropdown__list-item-wr dropdown__list-item-wr--with-icon icon-settings" }
const _hoisted_9 = { class: "dropdown__list-item-text" }
const _hoisted_10 = { class: "dropdown__list-item-wr dropdown__list-item-wr--with-icon icon-logout" }
const _hoisted_11 = { class: "dropdown__list-item-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "settings__btn settings__premium-btn",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goToSubscription())),
      type: "button"
    }, _toDisplayString(_ctx.$t("goPremium")), 1),
    _cache[3] || (_cache[3] = _createElementVNode("button", {
      class: "settings__btn settings__info-btn",
      type: "button"
    }, null, -1)),
    _createElementVNode("button", {
      class: "settings__btn settings__full-screen-btn",
      disabled: !$setup.isValidFocusModeRouteName,
      type: "button",
      onClick: $setup.onFullScreenBtn
    }, null, 8, _hoisted_2),
    _createElementVNode("button", {
      class: "settings__btn settings__settings-btn",
      onClick: $setup.showSettingsModal,
      type: "button"
    }),
    _createVNode($setup["AppDropdown"], null, {
      trigger: _withCtx(() => _cache[2] || (_cache[2] = [
        _createElementVNode("button", {
          class: "settings__btn settings__account-btn",
          type: "button"
        }, null, -1)
      ])),
      options: _withCtx(() => [
        _createElementVNode("ul", _hoisted_3, [
          _createElementVNode("li", _hoisted_4, [
            _createVNode(_component_RouterLink, {
              class: "dropdown__list-item-wr dropdown__list-item-wr--with-icon icon-account",
              to: "/settings/account"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("account")), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_6, [
            _createVNode(_component_RouterLink, {
              class: "dropdown__list-item-wr dropdown__list-item-wr--with-icon icon-subscription",
              to: "/settings/subscription"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("subscription")), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("li", {
            class: "dropdown__list-item",
            onClick: $setup.showSettingsModal
          }, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("settings")), 1)
            ])
          ]),
          _createElementVNode("li", {
            class: "dropdown__list-item",
            onClick: $setup.logout
          }, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("logOut")), 1)
            ])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode($setup["AppSettingsModal"], {
      "is-open": $setup.isShowSettingsModal,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => ($setup.isShowSettingsModal = false))
    }, null, 8, ["is-open"])
  ]))
}