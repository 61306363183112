const routesName = {
  preflop: "preflop",
  quiz: "quiz",
  study: "study",
  login: "login",
  gameSetup: "gameSetup",
  authRedirect: "authRedirect",
  settings: "settings",
  accountSettings: "accountSettings",
  subscriptionSettings: "subscriptionSettings",
  subscription: "subscription",
  pricing: "pricing",
  product: "product",
  faq: "faq",
  welcome: "welcome",
};

export default routesName;
