import { SeqWidgetActionTypeEnum } from "@/enums/seqWidgetEnum";
import {
  isCompoundAction,
  isDiscardAction,
  isSingleUpCardAction,
  isStreetAction,
} from "@/features/sequence/action";

import type {
  SeqWidgetAnyActionWithOrder,
  SeqWidgetCompoundActionSingle,
  SeqWidgetCompoundActionSingleOrStreetActionGroup,
  SeqWidgetCompoundActionWithOrder,
  SeqWidgetDiscardActionWithOrder,
  SeqWidgetNormalizedCompoundActionSingle,
  SeqWidgetNormalizedProps,
  SeqWidgetNormalizedStreetActionGroup,
  SeqWidgetSingleUpCardWithOrder,
  SeqWidgetStreetActionGroup,
  SeqWidgetStreetActionWithOrder,
} from "@/types/seqWidget";

export const isSeqWidgetCompoundActionSingle = (
  actionData: SeqWidgetCompoundActionSingleOrStreetActionGroup
): actionData is SeqWidgetCompoundActionSingle => {
  return actionData.type === SeqWidgetActionTypeEnum.single;
};

export const isSeqWidgetStreetActionGroup = (
  actionData: SeqWidgetCompoundActionSingleOrStreetActionGroup
): actionData is SeqWidgetStreetActionGroup => {
  return actionData.type === SeqWidgetActionTypeEnum.group;
};

export const isSeqWidgetNormalizedCompoundActionSingle = (
  actionData: SeqWidgetNormalizedProps
): actionData is SeqWidgetNormalizedCompoundActionSingle => {
  return actionData.type === SeqWidgetActionTypeEnum.single;
};

export const isSeqWidgetNormalizedPropsStreetActionGroup = (
  actionData: SeqWidgetNormalizedProps
): actionData is SeqWidgetNormalizedStreetActionGroup => {
  return actionData.type === SeqWidgetActionTypeEnum.group;
};

export const isSeqWidgetStreetActionWithOrder = (
  actionData: SeqWidgetAnyActionWithOrder
): actionData is SeqWidgetStreetActionWithOrder => {
  return isStreetAction(actionData.seqAction);
};

export const isSeqWidgetCompoundActionWithOrder = (
  actionData: SeqWidgetAnyActionWithOrder
): actionData is SeqWidgetCompoundActionWithOrder => {
  return isCompoundAction(actionData.seqAction);
};

export const isSeqWidgetDiscardActionWithOrder = (
  actionData: SeqWidgetCompoundActionWithOrder
): actionData is SeqWidgetDiscardActionWithOrder => {
  return isDiscardAction(actionData.seqAction);
};
export const isSeqWidgetUpCardsActionWithOrder = (
  actionData: SeqWidgetCompoundActionWithOrder
): actionData is SeqWidgetSingleUpCardWithOrder => {
  return isSingleUpCardAction(actionData.seqAction);
};
