import type { UpCardsRecords } from "@/types/upCards";
import { parseCardsFromString } from "@/features/cards/cards";

export const createUpCardsRecordsFromString = (
  upCardsString: string,
  playerNames: string[]
): UpCardsRecords => {
  const upCardsArray = upCardsString.split(",");
  const upCardsRecords: UpCardsRecords = {};

  playerNames.forEach((playerName) => {
    upCardsRecords[playerName] = [];
  });

  upCardsArray.forEach((cards, index) => {
    const playerName = playerNames[index % playerNames.length];
    upCardsRecords[playerName].push(...parseCardsFromString(cards));
  });

  return upCardsRecords;
};

export const getSingleCardPerPlayer = (
  upCardsStr: string,
  playerNames: string[]
) => {
  const records = createUpCardsRecordsFromString(upCardsStr, playerNames);
  return Object.keys(records)
    .map((playerName) => records[playerName][0] || "")
    .filter((card) => card)
    .join(",");
};
