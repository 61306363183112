import type { SeqActionAny, SeqActionBoardCards } from "@/types/seqAction";
import { isBoardCardsAction } from "@/features/sequence/action";
import { getNumFinishedRounds } from "@/features/sequence/utils";

const numCardsInRound: Record<string, number> = {
  0: 0,
  1: 3,
  2: 4,
  3: 5,
};

export const getPrevCardSeqBeforeIdx = (arr: SeqActionAny[], idx: number) => {
  let previousCard = null;

  for (let i = 0; i < idx; i++) {
    if (isBoardCardsAction(arr[i])) {
      previousCard = arr[i] as SeqActionBoardCards;
    }
  }

  return previousCard ? previousCard.boardCards : null;
};

export const cardsActionIdxByNumCards = (
  array: SeqActionAny[],
  num: number
) => {
  for (let i = 0; i < array.length; i++) {
    if (
      isBoardCardsAction(array[i]) &&
      (array[i] as SeqActionBoardCards).boardCards.length === num
    ) {
      return i;
    }
  }
  return -1;
};

export const isFlopCardAction = (array: SeqActionAny[], idx: number) => {
  let cardsCount = 0;

  for (let i = 0; i < array.length; i++) {
    if (isBoardCardsAction(array[i])) {
      cardsCount++;
      if (cardsCount === 1) {
        return i === idx;
      }
    }
  }

  return false;
};

export const isSingleCardAction = (array: SeqActionAny[], idx: number) => {
  let firstCardsFound = false;

  for (let i = 0; i < array.length; i++) {
    if (isBoardCardsAction(array[i])) {
      if (!firstCardsFound) {
        firstCardsFound = true;
      } else if (i === idx) {
        return true;
      }
    }
  }

  return false;
};

export const isRiverCardAction = (array: SeqActionAny[], idx: number) => {
  let cardsCount = 0;

  for (let i = 0; i < array.length; i++) {
    if (isBoardCardsAction(array[i])) {
      cardsCount++;
      if (cardsCount === 3) {
        return i === idx;
      }
    }
  }

  return false;
};

export const getBoardCardsBeforeSeqPointer = (
  numFinishedRounds: number,
  boardCards: string[]
) => {
  const roundsToCardsMap = [0, 3, 4, 5];
  const maxIndex = roundsToCardsMap.length - 1;
  const index = Math.min(numFinishedRounds, maxIndex);

  return boardCards.slice(0, roundsToCardsMap[index]);
};

export const getNumCardsByRound = (
  numFinishedRounds: number,
  withCurrent = false
) => {
  if (withCurrent) {
    return numCardsInRound[numFinishedRounds];
  } else {
    return numCardsInRound[numFinishedRounds + 1] || undefined;
  }
};

export const getCardsArrFromString = (
  cards: string,
  actionIdx: number,
  sequence: SeqActionAny[]
) => {
  const currentHandIdx = getNumFinishedRounds(actionIdx, sequence);
  const cardList = cards.split(" ");

  if (currentHandIdx === 0) {
    return cardList.slice(0, 3);
  } else if (currentHandIdx === 1) {
    return [cardList[3]];
  } else {
    return [cardList[4]];
  }
};
