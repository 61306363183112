import { useGameStore } from "@/stores/game";
import { deepCopy } from "@/utils/deepCopy";
import { sortArrayFromIdx } from "@/utils/array";
import { isPlayerFolded } from "@/features/pokerPlayers";
import {
  isGameEndingActionName,
  isRoundEndingActionName,
} from "@/features/action/actionGeneral";
import {
  getEmptyAction,
  getSkippedAction,
  isActionWithData,
  isBoardCardsAction,
  isFoldAction,
} from "@/features/sequence/action";

import { PokerActionsEnum } from "@/poker-query-lib/enums/poker-actions.enum";

import type { SeqAction, SeqActionAny } from "@/types/seqAction";
import type { StateInfo } from "@/types/general";
import type { GetActionIdxOnTablePlayerClickParams } from "@/types/sequence";
import { getSeqWithGroups } from "@/features/sequence/widget/groups";
import {
  isSeqWidgetStreetActionGroup,
  isSeqWidgetStreetActionWithOrder,
} from "@/features/sequence/widget/guards";
import type {
  SeqWidgetStreetActionGroup,
  SeqWidgetStreetActionWithOrder,
} from "@/types/seqWidget";

export const createSeqWithSkippedAndEmptyActions = (
  arr: SeqAction[],
  numOfPlayers: number
) => {
  const groups = getSeqWithGroups(arr);
  const rowsResult: any[][] = [];
  let numberOfActivePlayers = numOfPlayers;

  for (const group of groups) {
    if (isSeqWidgetStreetActionGroup(group)) {
      const rowResult: SeqWidgetStreetActionWithOrder[][] = [];
      if (group.data.length < numberOfActivePlayers) {
        rowResult.push(group.data);
        for (let j = 0; j < numberOfActivePlayers - group.data.length; j++) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          rowResult.push([getEmptyAction()]);
        }
      } else {
        for (let j = 0; j < group.data.length; j += numberOfActivePlayers) {
          const row = group.data.slice(j, j + numberOfActivePlayers);
          const diff = deepCopy(row).filter((item) =>
            isFoldAction(item.seqAction)
          ).length;
          numberOfActivePlayers -= diff;
          rowResult.push(row);
          if (diff) {
            j += diff;
          }
        }

        const foldedPlayersIdxs: number[] = [];
        for (const row of rowResult) {
          for (let a = 0; a < row.length; a++) {
            if (foldedPlayersIdxs.includes(a)) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              row.splice(a, 0, getSkippedAction());
            } else if (isFoldAction(row[a].seqAction)) {
              foldedPlayersIdxs.push(a);
            }
          }
        }
      }
      rowsResult.push(rowResult);
    } else {
      rowsResult.push([[group.data.seqAction]]);
    }
  }

  return rowsResult.flat(2).map((item) => {
    if ("seqAction" in item && isSeqWidgetStreetActionWithOrder(item)) {
      return item.seqAction;
    } else {
      return item;
    }
  });
};

export const checkPreviousFoldOfPlayer = (
  actionOrder: number,
  playersNumInRound: number,
  group: SeqWidgetStreetActionGroup
) => {
  const rowResult: SeqWidgetStreetActionWithOrder[][] = [];

  for (let j = 0; j < group.data.length; j += playersNumInRound) {
    rowResult.push(group.data.slice(j, j + playersNumInRound));
  }

  const foldedPlayersIdxs: number[] = [];
  for (let i = 0; i < rowResult.length; i++) {
    for (const item of rowResult[i]) {
      if (isFoldAction(item.seqAction)) {
        foldedPlayersIdxs.push(item.order);
      }
      if (foldedPlayersIdxs.includes(actionOrder - i * playersNumInRound)) {
        return true;
      }
    }
  }
  return false;
};

export const parseSeqStrToArr = (sequence: string) => {
  return sequence.match(/[br]\d*|f[/$]?|[ck][/$]?|d[0-5]/gm) || [];
};

export const stackForLastCall = (stateInfo: StateInfo, playerIndex: number) => {
  const { roundContrib, remainingStack } = stateInfo;
  const newContrib = Math.max(...roundContrib);
  const outstanding = newContrib - roundContrib[playerIndex];
  return remainingStack[playerIndex] - outstanding;
};

export const contribForPlayer = (stateInfo: StateInfo, playerIdx: number) => {
  const copy = deepCopy(stateInfo);
  copy.roundContrib[playerIdx] = Math.max(...stateInfo.roundContrib);
  return copy.roundContrib[playerIdx];
};

export const isRoundOrGameEnd = (action: string) => {
  return isRoundEndingActionName(action) || isGameEndingActionName(action);
};

export const getNumFoldedActions = (seqStr: string) => {
  return seqStr.match(new RegExp(PokerActionsEnum.FOLD, "g"))?.length || 0;
};

export const getNumFinishedRounds = (idx: number, sequence: SeqActionAny[]) => {
  let res = 0;
  for (let i = 0; i < idx; i++) {
    const action = sequence[i];
    if (
      isActionWithData(action) &&
      isRoundEndingActionName(action.chosenAction)
    ) {
      res += 1;
    }
  }
  return res;
};
export const getNumFinishedFromSeqStr = (sequence: string) => {
  return sequence.split("/").length - 1;
};

export const isPlayerHasFoldedActions = (
  action: SeqAction,
  sequence: SeqActionAny[]
) => {
  let res = false;
  for (const element of sequence) {
    if (!isBoardCardsAction(element) && isActionWithData(element)) {
      const playerIndex = action.stateInfo.playerIndex;
      const actionData = element;
      if (
        actionData.stateInfo.playerIndex === playerIndex &&
        isFoldAction(actionData)
      ) {
        res = true;
        break;
      }
    }
  }
  return res;
};

export const getActionIdxOnTablePlayerClick = (
  data: GetActionIdxOnTablePlayerClickParams
) => {
  const {
    activeAction,
    player,
    sequence,
    seqPointer,
    playersWithAdditionalInfo,
  } = data;
  const gameStore = useGameStore();
  if (!gameStore.configByConnectionName || !isActionWithData(activeAction)) {
    return null;
  }
  const firstPlayerIdxInRound =
    gameStore.configByConnectionName.firstPlayer[
      getNumFinishedRounds(seqPointer, sequence)
    ];
  let sortedNamesByRound = sortArrayFromIdx(
    gameStore.playerNames,
    firstPlayerIdxInRound
  );
  const foldedPlayerNames = playersWithAdditionalInfo
    .filter(isPlayerFolded)
    .map((playerWithAddInfo) => playerWithAddInfo.name);
  sortedNamesByRound = sortedNamesByRound.filter(
    (name) => !foldedPlayerNames.includes(name)
  );
  const currentPlayerIdx = sortedNamesByRound.indexOf(player.name);
  const activePlayerName =
    gameStore.playerNames[activeAction.stateInfo.playerIndex];
  const activePlayerIdx = sortedNamesByRound.indexOf(activePlayerName);

  if (currentPlayerIdx > activePlayerIdx) {
    return seqPointer + currentPlayerIdx - activePlayerIdx;
  }
  return null;
};
