import { computed } from "vue";
import { useRoute } from "vue-router";
import { useLayoutStore } from "@/stores/layout";
import routesName from "@/constants/routesName";

export const useFocusMode = () => {
  const layoutStore = useLayoutStore();
  const route = useRoute();
  let focusModeTimer: ReturnType<typeof setTimeout> | null;

  const isValidFocusModeRouteName = computed(() => {
    if (route) {
      return [routesName.preflop, routesName.quiz, routesName.study].includes(
        route.name as string
      );
    }
    return false;
  });

  const changeFocusModeState = () => {
    layoutStore.changeFocusModeToastVisibility(!layoutStore.isActiveFocusMode);
    layoutStore.changeFocusModeState();
    if (focusModeTimer) {
      clearTimeout(focusModeTimer);
    }
    focusModeTimer = setTimeout(() => {
      layoutStore.changeFocusModeToastVisibility(false);
      focusModeTimer = null;
    }, 3000);
  };

  return {
    isValidFocusModeRouteName,
    changeFocusModeState,
  };
};
