import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout-body" }
const _hoisted_2 = { class: "layout-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["layout", { 'layout--expanded': $setup.layoutStore.isActiveFocusMode }])
  }, [
    _createVNode(_Transition, {
      name: "header",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        ($setup.layoutStore.isAppHeaderInAbsolutePos)
          ? (_openBlock(), _createBlock($setup["HeaderComponent"], {
              key: 0,
              class: "header--absolute-pos",
              navigation: $setup.gameNavigation,
              onMouseleave: $setup.onMouseLeave
            }, null, 8, ["navigation"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode($setup["HeaderComponent"], {
      class: _normalizeClass({ 'header--hidden': $setup.layoutStore.isActiveFocusMode }),
      navigation: $setup.gameNavigation
    }, null, 8, ["class", "navigation"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_RouterView, null, {
          default: _withCtx(({ Component }) => [
            _createVNode(_Transition, {
              name: "fade",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        })
      ])
    ])
  ], 2))
}