import {
  settingsFieldsPreflop,
  settingsFieldsStudy,
} from "@/constants/gameSettings";
import { useGameStore } from "@/stores/game";
import { useSequenceStore } from "@/stores/sequence";
import { useStudySequenceStore } from "@/stores/studySequence";
import {
  SeqActionStateEnum,
  SeqActionTypeEnum,
  SeqActionUiStateEnum,
} from "@/enums/sequenceEnum";
import { GameTypeEnum } from "@/enums/gameEnum";
import pokerApi from "@/services/pokerApi";
import {
  getBoardCardsAction,
  getOpenAction,
  getTerminatedAction,
  getUpCardAction,
  isActionWithData,
} from "@/features/sequence/action";
import { parseCardsFromString } from "@/features/cards/cards";
import {
  getStrategyFromConfig,
  isDrawGameCategory,
  isFlopGameCategory,
  isStudGameCategory,
} from "@/features/gameSettings";
import { createSeqWithSkippedAndEmptyActions } from "@/features/sequence/utils";

import type { GameCategoryEnum } from "@/enums/gameEnum";
import type { SeqStateObject } from "@/types/sequence";
import type {
  FlopGameQuizSetup,
  QuizSetup,
  StudGameQuizSetup,
} from "@/types/quiz";
import { BoardMode, GameMode, UpCardsMode } from "@/enums/quizEnum";
import type {
  SeqAction,
  SeqActionAny,
  SeqActionTerminated,
} from "@/types/seqAction";
import router from "@/router";
import { createUpCardsRecordsFromString } from "@/features/cards/upCards";
import type { UpCardsRecords } from "@/types/upCards";
import type { GameConfig } from "@/types/gameSettings";

export interface InitialQuizPageQueries {
  connectionName: string;
  seq: string;
  boardCards?: string;
  upCards?: string;
  quizSetup?: QuizSetup;
  cameFrom: string;
}

export interface QuizPageQueries {
  connectionName: string;
  seq: string;
  boardCards?: string;
  upCards?: string;
  quizSetup: QuizSetup;
  cameFrom: string;
}

const validateBoardCardsFromQuery = (
  boardCards: string,
  validationParams: {
    gameType?: GameTypeEnum;
    seq: string;
  }
): boolean => {
  const getCardList = (boardCardsStr: string): string[] | null => {
    return boardCardsStr.match(/.{1,2}/g);
  };
  const getRequiredAmountOfCards = (seqStr?: string): number => {
    if (!seqStr) {
      return 0;
    }
    const slashCount = (seqStr.match(/\//g) || []).length;
    return slashCount === 1
      ? 3
      : slashCount === 2
      ? 4
      : slashCount === 3
      ? 5
      : 0;
  };
  const areCardsValid = (
    cardListArr: string[],
    gameTypeVar?: GameTypeEnum
  ): boolean => {
    const cardSet = new Set();
    const cardRegex = /^[2-9TJQKA][dchs]$/;
    const shortDeckCardRegex = /^[6-9TJQKA][dchs]$/;
    const currentCardRegex =
      gameTypeVar === GameTypeEnum.shortdeck ? shortDeckCardRegex : cardRegex;

    for (const card of cardListArr) {
      if (!currentCardRegex.test(card) || cardSet.has(card)) {
        return false;
      }
      cardSet.add(card);
    }

    return true;
  };

  const cardList = getCardList(boardCards);
  if (!cardList || cardList.length === 0) {
    return false;
  }

  const requiredCards = getRequiredAmountOfCards(validationParams.seq);
  if (requiredCards > 0 && cardList.length < requiredCards) {
    return false;
  }

  const cardsAreValid = areCardsValid(cardList, validationParams.gameType);
  if (!cardsAreValid) {
    return false;
  }

  if (validationParams.seq === "open") {
    // Add any special handling for 'open' if required
    return true;
  }

  return true;
};

const validateUpCardsFromQuery = (
  upCards: string,
  validationParams: {
    seq: string;
    numPlayers: number;
  },
  heroCard?: string
): boolean => {
  const getCardListPerPlayer = (upCardsStr: string): string[][] => {
    return upCardsStr.split(",").map((cards) => cards.match(/.{1,2}/g) || []);
  };

  const getRoundNumber = (seqStr: string): number => {
    return (seqStr.match(/\//g) || []).length;
  };

  const getRequiredCardsPerPlayer = (round: number): number => {
    return round + 1;
  };

  const isCardValid = (card: string): boolean => {
    const cardRegex = /^[2-9TJQKA][dchs]$/;
    return cardRegex.test(card);
  };

  const areCardsValid = (cardList: string[]): boolean => {
    const cardSet = new Set();

    for (const card of cardList) {
      if (!isCardValid(card) || cardSet.has(card)) {
        return false;
      }
      cardSet.add(card);
    }

    return true;
  };

  if (heroCard && !isCardValid(heroCard)) {
    return false;
  }

  const playersCards = getCardListPerPlayer(upCards);

  if (playersCards.length !== validationParams.numPlayers) {
    return false;
  }

  const roundNumber = getRoundNumber(validationParams.seq);
  const requiredCardsPerPlayer = getRequiredCardsPerPlayer(roundNumber);

  let playersWithRequiredCards = 0;

  for (const playerCards of playersCards) {
    const isFinalStreet = roundNumber === 4;
    const maxCardsPerPlayer = isFinalStreet ? 4 : requiredCardsPerPlayer;

    if (playerCards.length > maxCardsPerPlayer) {
      return false;
    }

    if (!areCardsValid(playerCards)) {
      return false;
    }

    if (playerCards.length === maxCardsPerPlayer) {
      playersWithRequiredCards++;
    }
  }

  const minPlayersWithRequiredCards = Math.min(2, validationParams.numPlayers);

  const isFinalRound = roundNumber === 4;
  if (isFinalRound) {
    return playersWithRequiredCards >= minPlayersWithRequiredCards;
  }

  return playersWithRequiredCards >= minPlayersWithRequiredCards;
};

export const handlePreflopSettingsQuery = (
  connectionName?: string,
  upCards?: string
) => {
  const gameStore = useGameStore();
  const seqStore = useSequenceStore();

  if (connectionName) {
    const gameStrategy = getStrategyFromConfig(
      connectionName,
      settingsFieldsPreflop
    );
    if (gameStrategy) {
      if (upCards) {
        gameStore.setPreflopGameStrategy({
          ...gameStrategy,
          upCards,
        });
      } else {
        gameStore.setPreflopGameStrategy(gameStrategy);
      }
    }
  } else {
    gameStore.setLastActivePreflopStrat();
    gameStore.updatePageQueries(seqStore.seqForRequest);
  }
};
export const handleStudySettingsQuery = (
  connectionName?: string,
  upCards?: string
) => {
  const gameStore = useGameStore();
  const studySeqStore = useStudySequenceStore();

  if (connectionName) {
    const gameStrategy = getStrategyFromConfig(
      connectionName,
      settingsFieldsStudy
    );
    if (gameStrategy) {
      if (upCards) {
        gameStore.setStudyGameStrategy({
          ...gameStrategy,
          upCards,
        });
      } else {
        gameStore.setStudyGameStrategy(gameStrategy);
      }
    }
  } else {
    gameStore.setLastActiveStudyStrat();
    gameStore.updatePageQueries(studySeqStore.seqForRequest);
  }
};

const addBoardCardsToSeq = (seq: SeqActionAny[], boardCards: string[]) => {
  const cards = boardCards;
  let slashCount = 0;
  const newSeq = seq;

  for (let i = 0; i < newSeq.length; i++) {
    const actionItem = newSeq[i];

    if (isActionWithData(actionItem) && actionItem.chosenAction.includes("/")) {
      slashCount++;

      let cardsToAdd: string[] = [];
      if (slashCount === 1) {
        cardsToAdd = cards.slice(0, 3);
      } else if (slashCount === 2) {
        cardsToAdd = cards.slice(0, 4);
      } else if (slashCount === 3) {
        cardsToAdd = cards.slice(0, 5);
      }

      const newAction = getBoardCardsAction(cardsToAdd);

      newSeq.splice(i + 1, 0, newAction);
      i++;
    }
  }

  return newSeq;
};
const addUpCardsToSeq = (
  seq: SeqActionAny[],
  upCards: string,
  playerNames: string[],
  heroCard?: string
) => {
  const newSeq = seq;
  const upCardsRecords = createUpCardsRecordsFromString(upCards, playerNames);

  const initialUpCards: UpCardsRecords = {};
  for (const [player, cards] of Object.entries(upCardsRecords)) {
    initialUpCards[player] = cards.slice(0, 1);
  }
  const initialUpCardsAction = getUpCardAction(initialUpCards, heroCard);
  newSeq.unshift(initialUpCardsAction);

  let slashCount = 0;
  for (let i = 1; i < newSeq.length; i++) {
    const actionItem = newSeq[i];

    if (isActionWithData(actionItem) && actionItem.chosenAction.includes("/")) {
      slashCount++;

      const updatedUpCards = Object.fromEntries(
        Object.entries(upCardsRecords).map(([player, cards]) => [
          player,
          cards.slice(0, slashCount + 1),
        ])
      );

      const newAction = getUpCardAction(
        updatedUpCards,
        slashCount === 4 ? heroCard : undefined
      );

      newSeq.splice(i + 1, 0, newAction);
      i++;
    }
  }

  return newSeq;
};

export const getSeqFromSeqStateReq = (
  inputArray: SeqStateObject[],
  options?: {
    isPreflop?: boolean;
    withSkippedAndEmptyActions?: boolean;
    boardCards?: string[];
    upCardsOptions?: {
      upCards?: string;
      heroCard?: string;
      playerNames?: string[];
    };
  }
) => {
  const numPlayers = inputArray[0].stateInfo.folded.length;
  const seq: (SeqAction | SeqActionTerminated)[] = inputArray.map(
    (item, idx) => {
      const { sequence, actionsList, stateInfo, isDiscardAction } = item;

      const nextItemSequence = inputArray[idx + 1]?.sequence;
      const seqForAction = nextItemSequence
        ? nextItemSequence.match(/[br]\d*|f[/$]?|[ck][/$]?|d[0-5]/gm) ?? []
        : [];

      const chosenAction = seqForAction.length
        ? seqForAction[seqForAction.length - 1]
        : "";
      const actionType = isDiscardAction
        ? SeqActionTypeEnum.discardAction
        : SeqActionTypeEnum.action;
      const actionState = chosenAction
        ? SeqActionStateEnum.performed
        : SeqActionStateEnum.toAct;

      if (options?.isPreflop && inputArray[idx].sequence.endsWith("/")) {
        return getTerminatedAction(inputArray[idx].sequence, stateInfo);
      } else {
        return {
          type: actionType,
          state: actionState,
          uiState: SeqActionUiStateEnum.visible,
          sequence: sequence.length ? sequence : "open",
          chosenAction,
          actionsList,
          stateInfo,
        };
      }
    }
  );
  const seqWithSkippingAndPreActions = createSeqWithSkippedAndEmptyActions(
    seq as SeqAction[],
    numPlayers
  );
  const seqBasedOnOptions = options?.withSkippedAndEmptyActions
    ? seqWithSkippingAndPreActions
    : seq;

  if (
    !options?.boardCards &&
    !options?.boardCards?.length &&
    !options?.upCardsOptions?.upCards
  ) {
    return seqBasedOnOptions;
  }

  if (options.boardCards) {
    return addBoardCardsToSeq(seqBasedOnOptions, options.boardCards);
  } else if (
    options.upCardsOptions?.upCards &&
    options.upCardsOptions?.playerNames
  ) {
    return addUpCardsToSeq(
      seqBasedOnOptions,
      options.upCardsOptions.upCards,
      options.upCardsOptions.playerNames,
      options.upCardsOptions?.heroCard
    );
  }

  return seq;
};

export const handlePreflopSeqQuery = async (
  seq: string,
  gameConfig: GameConfig,
  options?: {
    upCards?: string;
  }
) => {
  const seqStore = useSequenceStore();
  const gameStore = useGameStore();
  if (!seq) {
    await seqStore.initPreflopSeqStore();
    return;
  }
  if (isStudGameCategory(gameConfig.gameCategory)) {
    if (
      options?.upCards &&
      validateUpCardsFromQuery(options.upCards, {
        seq,
        numPlayers: gameConfig.numPlayers,
      })
    ) {
      if (seq !== "open") {
        seqStore.setSeqStateFromQuery(seq, {
          upCards: options.upCards,
        });
      } else {
        await seqStore.initPreflopSeqStore({
          upCards: options.upCards,
        });
      }
    } else {
      gameStore.setIsSharingLinkInvalid(true);
    }
  } else {
    seqStore.setSeqStateFromQuery(seq);
  }
};

export const handleStudySeqQuery = async (
  seq: string,
  gameConfig: GameConfig,
  options?: {
    boardCards?: string;
    upCards?: string;
    heroCard?: string;
  }
) => {
  const studySeqStore = useStudySequenceStore();
  const gameStore = useGameStore();
  if (!seq || seq === "open") {
    await studySeqStore.initStudySeqStore(options);
    return;
  }
  if (isStudGameCategory(gameConfig.gameCategory)) {
    if (
      options?.upCards &&
      validateUpCardsFromQuery(
        options.upCards,
        {
          seq,
          numPlayers: gameConfig.numPlayers,
        },
        options?.heroCard
      )
    ) {
      studySeqStore.setSeqStateFromQuery(seq, {
        upCards: options.upCards,
        heroCard: options.heroCard,
      });
    } else {
      gameStore.setIsSharingLinkInvalid(true);
    }
  } else if (isDrawGameCategory(gameConfig.gameCategory)) {
    studySeqStore.setSeqStateFromQuery(seq);
  } else if (isFlopGameCategory(gameConfig.gameCategory) && seq.includes("/")) {
    if (
      options?.boardCards &&
      validateBoardCardsFromQuery(options.boardCards, {
        gameType: gameConfig.gameType,
        seq,
      })
    ) {
      studySeqStore.setSeqStateFromQuery(seq, {
        boardCards: parseCardsFromString(options.boardCards),
      });
    } else {
      gameStore.setIsSharingLinkInvalid(true);
    }
  } else {
    studySeqStore.setSeqStateFromQuery(seq);
  }
};

export const initQuizSequence = async (
  upCards?: string
): Promise<SeqActionAny[]> => {
  const res = await pokerApi.fetchActionListingJson("open", { upCards });
  const startAction = getOpenAction(res.data.data);
  return [startAction];
};

export const initQuizSequenceFromQuery = async (
  seq: string,
  options?: {
    boardCards?: string;
    upCardsOptions?: {
      upCards?: string;
      playerNames?: string[];
    };
  }
): Promise<SeqActionAny[] | void> => {
  try {
    const res = await pokerApi.fetchSeqStateHistory(seq, {
      upCards: options?.upCardsOptions?.upCards,
    });
    return getSeqFromSeqStateReq(res.data.data, {
      boardCards: options?.boardCards
        ? parseCardsFromString(options?.boardCards)
        : undefined,
      upCardsOptions: options?.upCardsOptions,
    });
  } catch {
    const gameStore = useGameStore();

    gameStore.setIsSharingLinkInvalid(true);
    return undefined;
  }
};

export const handleQuizSeqQuery = async (
  pageQueries: QuizPageQueries,
  gameConfig: GameConfig
): Promise<SeqActionAny[] | void> => {
  const gameStore = useGameStore();

  if (!pageQueries.seq) {
    gameStore.setIsSharingLinkInvalid(true);
    return undefined;
  }
  if (isStudGameCategory(gameConfig.gameCategory)) {
    if (
      pageQueries.upCards &&
      validateUpCardsFromQuery(pageQueries.upCards, {
        seq: pageQueries.seq,
        numPlayers: gameConfig.numPlayers,
      })
    ) {
      return initQuizSequenceFromQuery(pageQueries.seq, {
        upCardsOptions: {
          upCards: pageQueries.upCards,
          playerNames: gameConfig.playerNames,
        },
      });
    } else {
      gameStore.setIsSharingLinkInvalid(true);
      return undefined;
    }
  } else if (isDrawGameCategory(gameConfig.gameCategory)) {
    if (pageQueries.seq === "open") {
      return initQuizSequence();
    } else {
      return initQuizSequenceFromQuery(pageQueries.seq);
    }
  } else if (
    isFlopGameCategory(gameConfig.gameCategory) &&
    pageQueries.seq.includes("/")
  ) {
    if (pageQueries.seq === "open") {
      return initQuizSequence();
    } else if (
      pageQueries.boardCards &&
      validateBoardCardsFromQuery(pageQueries.boardCards, {
        gameType: gameConfig.gameType,
        seq: pageQueries.seq,
      })
    ) {
      return initQuizSequenceFromQuery(pageQueries.seq, {
        boardCards: pageQueries.boardCards,
      });
    } else {
      gameStore.setIsSharingLinkInvalid(true);
      return undefined;
    }
  } else {
    return initQuizSequenceFromQuery(pageQueries.seq);
  }
};

export const parseQueryString = (
  queryString: string
): { [key: string]: string | undefined } => {
  const queryStart = queryString.indexOf("?");

  if (queryStart === -1 || queryStart === queryString.length - 1) {
    return {};
  }

  const query = queryString.slice(queryStart + 1);

  return query.split("&").reduce((acc, param) => {
    const [key, value] = param.split("=");

    if (key !== undefined && key !== "") {
      const decodedKey = decodeURIComponent(key);
      acc[decodedKey] = value ? decodeURIComponent(value) : undefined;
    }
    return acc;
  }, {} as { [key: string]: string | undefined });
};

export const isQuizSetupFromQueryValid = (
  gameCategory: GameCategoryEnum,
  quizSetup?: QuizSetup
): quizSetup is QuizSetup => {
  const gameStore = useGameStore();

  const validGameModes = [GameMode.spot, GameMode.street, GameMode.fullHand];
  const validBoardModes = [
    BoardMode.current,
    BoardMode.lastRandom,
    BoardMode.fullyRandom,
  ];
  const validUpCardsModes = [
    UpCardsMode.current,
    UpCardsMode.lastRandom,
    UpCardsMode.fullyRandom,
  ];

  if (!quizSetup) {
    gameStore.setIsSharingLinkInvalid(true);
    return false;
  }
  if (
    isFlopGameCategory(gameCategory) &&
    "boardMode" in quizSetup &&
    validGameModes.includes(quizSetup.gameMode) &&
    validBoardModes.includes(quizSetup.boardMode)
  ) {
    return true;
  } else if (
    isStudGameCategory(gameCategory) &&
    "upCardsMode" in quizSetup &&
    validGameModes.includes(quizSetup.gameMode) &&
    validUpCardsModes.includes(quizSetup.upCardsMode)
  ) {
    return true;
  } else if (
    isDrawGameCategory(gameCategory) &&
    "gameMode" in quizSetup &&
    validGameModes.includes(quizSetup.gameMode)
  ) {
    return true;
  } else {
    gameStore.setIsSharingLinkInvalid(true);
    return false;
  }
};

export const updateQuizPageQueries = (pageQueries: QuizPageQueries) => {
  const isBoardModeExist = "boardMode" in pageQueries.quizSetup;
  const isUpCardsModeExist = "upCardsMode" in pageQueries.quizSetup;
  router.replace({
    query: {
      connectionName: pageQueries.connectionName,
      seq: pageQueries.seq,
      boardCards: pageQueries.boardCards?.length
        ? pageQueries.boardCards
        : undefined,
      upCards: pageQueries.upCards?.length ? pageQueries.upCards : undefined,
      gameMode: pageQueries.quizSetup.gameMode,
      boardMode: isBoardModeExist
        ? (pageQueries.quizSetup as FlopGameQuizSetup).boardMode
        : undefined,
      upCardsMode: isUpCardsModeExist
        ? (pageQueries.quizSetup as StudGameQuizSetup).upCardsMode
        : undefined,
      cameFrom: pageQueries.cameFrom,
    },
  });
};
