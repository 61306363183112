import { PlayerCardTypeEnum } from "@/enums/playerEnum";
import type { PlayerCard } from "@/types/player";

export const getDefaultUpCard = (card: string) => {
  return {
    isBack: false,
    type: PlayerCardTypeEnum.upCard,
    value: card,
  };
};
export const isPlayerCardNotUpCard = (card: PlayerCard) => {
  return card.type !== PlayerCardTypeEnum.upCard;
};
