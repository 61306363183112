import { onBeforeUnmount, onMounted } from "vue";
import { useFocusMode } from "@/composables/useFocusMode";
import { isDomActiveElEditable } from "@/utils/dom";

export const useKeyDown = () => {
  const { changeFocusModeState, isValidFocusModeRouteName } = useFocusMode();

  const handleKeyDown = (e: KeyboardEvent) => {
    if (isDomActiveElEditable() || !isValidFocusModeRouteName.value) {
      return;
    }
    if (e.key === "F" || e.key === "f") {
      changeFocusModeState();
    }
  };

  onMounted(() => {
    window.addEventListener("keydown", handleKeyDown);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("keydown", handleKeyDown);
  });
};
