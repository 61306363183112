import { axiosNoCache, axiosWithCache } from "@/services/api";
import { useGameStore } from "@/stores/game";
import { useSequenceStore } from "@/stores/sequence";
import { useStudySequenceStore } from "@/stores/studySequence";
import type { AxiosResponse } from "axios";
import type { GameConfig } from "@/types/gameSettings";
import type { ApiClientResponse } from "@/types/api/ApiClientResponse";
import type {
  ActionListingData,
  ActionListingReqParams,
} from "@/types/actionListingJson";
import type { DealHandResponse, Hand, TakeActionResponse } from "@/types/quiz";
import type { ActivityConfig } from "@/types/activityConfig";
import type { RouteRecordNameGeneric } from "vue-router";
import { GameCategoryEnum } from "@/enums/gameEnum";
import type { PreflopSequenceMap, SeqStateObject } from "@/types/sequence";
import routesName from "@/constants/routesName";
import type { MatrixRequestParams } from "@/types/matrix";
import { cachedFetch } from "@/utils/cacheApi";

const getStreamSize = async (
  reader: ReadableStreamDefaultReader
): Promise<number> => {
  const { done, value } = await reader.read();
  return done ? 0 : value.byteLength + (await getStreamSize(reader));
};

const getUpCardsForActivity = (): string => {
  const { currentPageName } = useGameStore();
  return currentPageName === routesName.preflop
    ? useSequenceStore().upCardsModule.getCardsForRequest()
    : currentPageName === routesName.study
    ? useStudySequenceStore().upCardsModule.getCardsForRequest()
    : "";
};

export default {
  dealHand(
    betSequence: string,
    params?: { boardCards?: string[]; upCards?: string }
  ): Promise<ApiClientResponse<DealHandResponse>> {
    const gameStore = useGameStore();
    const seed = Math.floor(10000 + Math.random() * 90000);
    const boardCardsParam = params?.boardCards?.length
      ? `&boardCards=${params?.boardCards.join("")}`
      : "";
    const upCardsParam = params?.upCards?.length
      ? `&upCards=${params?.upCards}`
      : "";
    const evsParam = gameStore.isCurrentGameStud ? `&evs=true` : "";

    return axiosNoCache.get(
      `poker-query/quiz/${gameStore.getActiveGameCategoryForRequest()}/${
        gameStore.connectionName
      }/deal-hand?betSequence=${betSequence}${boardCardsParam}${upCardsParam}${evsParam}&seed=${seed}`
    );
  },

  takeAction(
    action: string,
    nextQuery: string
  ): Promise<ApiClientResponse<TakeActionResponse>> {
    const gameStore = useGameStore();
    const seed = Math.floor(10000 + Math.random() * 90000);

    return axiosNoCache.get(
      `poker-query/quiz/${gameStore.getActiveGameCategoryForRequest()}/${
        gameStore.connectionName
      }/take-action?action=${action}&nextQuery=${nextQuery}&seed=${seed}`
    );
  },

  fetchSeqStateHistory(
    betSequence: string,
    params?: { upCards?: string }
  ): Promise<ApiClientResponse<SeqStateObject[]>> {
    const gameStore = useGameStore();
    const upCardsParam = params?.upCards?.length
      ? `&upCards=${params.upCards}`
      : "";
    return axiosWithCache.get(
      `poker-query/${
        gameStore.currentPageName as string
      }/${gameStore.getActiveGameCategoryForRequest()}/${
        gameStore.connectionName
      }/history?betSequence=${betSequence}${upCardsParam}`,
      { useCache: true }
    );
  },

  fetchGameConfigs(): Promise<ApiClientResponse<GameConfig[]>> {
    return axiosNoCache.get("poker-query/game-configs");
  },

  fetchActionListingJson(
    betSequence: string,
    reqParams?: ActionListingReqParams
  ): Promise<ApiClientResponse<ActionListingData>> {
    const gameStore = useGameStore();
    const params = {
      ...(reqParams?.boardCards?.length && {
        boardCards: reqParams.boardCards,
      }),
      ...(reqParams?.upCards?.length && {
        upCards: reqParams.upCards,
      }),
      betSequence,
    };
    return axiosWithCache.get(
      `poker-query/${
        gameStore.currentPageName as string
      }/${gameStore.getActiveGameCategoryForRequest(
        gameStore.connectionName
      )}/${gameStore.connectionName}/action-listing`,
      {
        params,
        useCache: true,
      }
    );
  },

  fetchPreflopSequence(): Promise<AxiosResponse<string>> {
    const gameStore = useGameStore();
    return axiosWithCache.get(
      `poker-query/${gameStore.currentPageName as string}/${
        gameStore.connectionName
      }/preflop-sequences`,
      {
        params: {
          markTerminal: true,
        },
        useCache: true,
      }
    );
  },

  fetchPreflopSequenceJsonMap(): Promise<AxiosResponse<PreflopSequenceMap>> {
    const gameStore = useGameStore();
    return axiosWithCache.get(
      `poker-query/${gameStore.currentPageName as string}/${
        gameStore.connectionName
      }/preflop-sequences-json-map`,
      { useCache: true }
    );
  },

  async fetchMatrix(
    betSequence: string,
    params?: MatrixRequestParams
  ): Promise<Response> {
    const gameStore = useGameStore();
    const baseUrl = `${
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_API_URL_WITH_CACHE || ""
        : process.env.VUE_APP_API_URL_NO_CACHE || ""
    }poker-query/${
      gameStore.currentPageName as string
    }/${gameStore.getActiveGameCategoryForRequest()}/${
      gameStore.connectionName
    }`;
    const betSeqParam = betSequence.length
      ? `betSequence=${betSequence}`
      : "open";

    let url: string;

    if (params?.boardCards?.length) {
      url = `${baseUrl}/study-matrix?${betSeqParam}&boardCards=${params.boardCards}`;
    } else if (
      gameStore.configByConnectionName?.gameCategory === GameCategoryEnum.draw
    ) {
      url = betSequence.includes("/")
        ? `${baseUrl}/study-matrix?${betSeqParam}`
        : `${baseUrl}/predraw-matrix?${betSeqParam}`;
    } else if (
      gameStore.configByConnectionName?.gameCategory === GameCategoryEnum.stud
    ) {
      const heroCardParam = params?.heroCard?.length
        ? `&holeCards=${params.heroCard}`
        : "";
      url = betSequence.includes("/")
        ? `${baseUrl}/study-matrix?${betSeqParam}${heroCardParam}&upCards=${params?.upCards}`
        : `${baseUrl}/thirdstreet-matrix?${betSeqParam}&upCards=${params?.upCards}`;
    } else {
      url = `${baseUrl}/preflop-matrix?${betSeqParam}`;
    }
    return cachedFetch(url, {
      method: "GET",
    });
  },

  async sendActivityConfig(response: any): Promise<void | Response> {
    if (
      response.headers["x-cache"]?.toLowerCase() === "hit from cloudfront" ||
      response.status !== 200
    ) {
      const gameStore = useGameStore();
      const endTime = new Date();
      const startTime = response?.config?.metadata?.startTime;
      const responseTime = startTime
        ? new Date(endTime).getTime() - new Date(startTime).getTime()
        : 0;
      const contentLength = response.headers["content-length"]
        ? parseInt(response.headers["content-length"], 10)
        : 0;
      const queryType =
        response.config.url.split("?")[0].split("/").pop() || "";

      const activityPayload: ActivityConfig = {
        gameName: gameStore.connectionName ?? "",
        queryType,
        contentLength,
        querySource: "CFC",
        queryLength: parseInt(response.headers["query-length"] ?? "0", 10),
        responseTime,
        httpCode: response.status,
        betSequence: response.config.params?.betSequence ?? "",
        markTerminal: !!response.config.params?.markTerminal,
        stateInfo: !!response.data.data?.stateInfo,
        holeCards: "",
        boardCards: response.config.params?.boardCards ?? "",
        upCards: getUpCardsForActivity(),
        pageName: gameStore.currentPageName as RouteRecordNameGeneric,
      };

      return axiosNoCache.post("poker/activity", activityPayload);
    }
    return Promise.resolve();
  },

  async sendMatrixActivityConfig(response: any): Promise<void | Response> {
    if (
      response.responseCopy.headers.get("X-Cache")?.toLowerCase() ===
        "hit from cloudfront" ||
      response.responseCopy.status !== 200
    ) {
      const gameStore = useGameStore();
      const endTime = new Date();
      const responseTime = response.startTime
        ? new Date(endTime).getTime() - new Date(response.startTime).getTime()
        : 0;
      const contentLength = await getStreamSize(
        response.responseCopy.body?.getReader()
      );

      const activityPayload: ActivityConfig = {
        gameName: gameStore.connectionName ?? "",
        queryType: "matrix",
        contentLength,
        querySource: "CFC",
        queryLength: parseInt(
          response.responseCopy.headers.get(["Query-Length"]) ?? "0",
          10
        ),
        responseTime,
        httpCode: response.responseCopy.status,
        betSequence: response.betSequence,
        markTerminal: false,
        stateInfo: !!response.responseCopy.data?.data?.stateInfo,
        holeCards: "",
        boardCards: response.boardCards,
        upCards: getUpCardsForActivity(),
        pageName: gameStore.currentPageName as RouteRecordNameGeneric,
      };

      return axiosNoCache.post("poker/activity", activityPayload);
    }
    return Promise.resolve();
  },
};
