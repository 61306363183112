import { type Ref, computed, ref } from "vue";
import { createUpCardsRecordsFromString } from "@/features/cards/upCards";
import { getNumFinishedRounds } from "@/features/sequence/utils";
import {
  isActionWithData,
  isFoldAction,
  isSingleUpCardAction,
} from "@/features/sequence/action";
import { deepCopy } from "@/utils/deepCopy";
import { UpCardsActiveActionStatusEnum } from "@/enums/upCardsEnum";

import type { UpCardsActiveAction, UpCardsRecords } from "@/types/upCards";
import type {
  SeqActionAny,
  SeqActionBoardCards,
  SeqActionSingleUpCard,
} from "@/types/seqAction";
import { SeqActionTypeEnum } from "@/enums/sequenceEnum";

export const useUpCards = (
  seqPointer: Ref<number>,
  sequence: Ref<SeqActionAny[]>
) => {
  const isOpenUpCardsModal = ref(false);
  const upCards = ref<UpCardsRecords | null>(null);
  const upCardsActionIdx = ref(-1);
  const upCardsActiveAction = ref<UpCardsActiveAction | null>(null);
  const heroCard = ref<string | null>(null);
  const playerNames = ref<string[]>([]);

  const foldedPlayerNames = computed(() => {
    if (upCardsActionIdx.value < 1) {
      return [];
    }
    const names = new Set();
    for (let i = upCardsActionIdx.value; i >= 0; i--) {
      const action = sequence.value[i];
      if (action && isActionWithData(action) && isFoldAction(action)) {
        names.add(playerNames.value[action.stateInfo.playerIndex]);
      }
    }
    return Array.from(names);
  });

  const initUpCards = (cards?: string, params?: { playerNames: string[] }) => {
    if (cards && cards.length && params?.playerNames) {
      playerNames.value = params.playerNames;
      setUpCards(createUpCardsRecordsFromString(cards, params.playerNames));
    } else {
      setUpCards(null);
    }
  };

  const resetUpCards = () => {
    for (const player in upCards.value) {
      upCards.value[player] = upCards.value[player].slice(0, 1);
    }
  };

  const setUpCards = (records: UpCardsRecords | null) => {
    upCards.value = records;
  };
  const setHeroCard = (card: string) => {
    heroCard.value = card;
  };

  const openUpCardsModal = (idx?: number) => {
    if (typeof idx === "number") {
      upCardsActionIdx.value = idx;
      const action = deepCopy(sequence.value[idx]);
      if (isSingleUpCardAction(action)) {
        upCardsActiveAction.value = {
          status: UpCardsActiveActionStatusEnum.notLastInSequence,
          value: action,
        };
      }
    } else {
      upCardsActionIdx.value = seqPointer.value;
      upCardsActiveAction.value = {
        status: UpCardsActiveActionStatusEnum.lastInSequence,
        value: deepCopy(getActiveUpCardsAction()),
      };
    }
    isOpenUpCardsModal.value = true;
  };
  const closeUpCardsModal = () => {
    isOpenUpCardsModal.value = false;
    upCardsActiveAction.value = null;
  };
  const confirmUpCardsModal = (upCardsRecords: UpCardsRecords) => {
    closeUpCardsModal();
    setUpCards(upCardsRecords);
  };

  const getActiveUpCardsAction = (idx = seqPointer.value) => {
    let upCardAction: SeqActionSingleUpCard | null = null;
    for (let i = idx; i >= 0; i--) {
      const action = sequence.value[i];
      if (isSingleUpCardAction(action)) {
        upCardAction = action;
        break;
      }
    }
    return upCardAction;
  };

  const addSingleUpCardToEachPlayer = (cards: string[]) => {
    if (upCards.value) {
      const playerKeys = Object.keys(upCards.value);

      cards.forEach((card, idx) => {
        if (card && upCards.value?.[playerKeys[idx]]) {
          upCards.value?.[playerKeys[idx]].push(card);
        }
      });
    }
  };

  const getUpCardsForRequest = (withCurrent = false) => {
    if (upCards.value) {
      return getUpCardsOnCurrentRound(withCurrent).join(",");
    } else {
      return "";
    }
  };

  const getHeroCardForRequest = () => {
    if (heroCard.value) {
      return getNumFinishedRounds(seqPointer.value, sequence.value) === 4
        ? heroCard.value
        : "";
    } else {
      return "";
    }
  };

  const getUpCardsOnCurrentRound = (
    withCurrent = false,
    pointer = seqPointer.value
  ) => {
    const result: string[] = [];
    if (upCards.value) {
      const numFinishedRounds = getNumFinishedRounds(
        withCurrent ? pointer + 1 : pointer,
        sequence.value
      );
      const players = Object.keys(upCards.value);

      players.forEach((player) => {
        let playerCards = "";
        for (let round = 0; round < numFinishedRounds + 1; round++) {
          if (upCards.value?.[player][round]) {
            playerCards += upCards.value[player][round];
          }
        }
        if (playerCards) {
          result.push(playerCards);
        }
      });
    }
    return result;
  };

  const handleHeroCard = (card: string) => {
    closeUpCardsModal();
    setHeroCard(card);
  };

  return {
    addSingleUpCardToEachPlayer,
    closeUpCardsModal,
    confirmUpCardsModal,
    foldedPlayerNames,
    getHeroCardForRequest,
    getUpCardsForRequest,
    getUpCardsOnCurrentRound,
    handleHeroCard,
    heroCard,
    initUpCards,
    isOpenUpCardsModal,
    openUpCardsModal,
    resetUpCards,
    setHeroCard,
    setUpCards,
    upCards,
    upCardsActionIdx,
    upCardsActiveAction,
  };
};
