export const CACHE_NAME = "api-cache";
export const MAX_CACHE_AGE = 7 * 24 * 60 * 60 * 1000; // 7 days

export const cleanUpOldCache = async () => {
  const cache = await caches.open(CACHE_NAME);
  const requests = await cache.keys();
  const now = Date.now();

  for (const request of requests) {
    await processCacheEntry(cache, request, now);
  }
};

const processCacheEntry = async (
  cache: Cache,
  request: Request,
  now: number
) => {
  const response = await cache.match(request);
  if (!response) {
    return;
  }

  const dateHeader = response.headers.get("X-Cached-Timestamp");
  if (!dateHeader) {
    return;
  }

  const cachedTime = Number(dateHeader);
  if (now - cachedTime > MAX_CACHE_AGE) {
    await cache.delete(request);
  }
};

export const cachedFetch = async (
  url: string,
  options?: RequestInit
): Promise<Response> => {
  const cache = await caches.open(CACHE_NAME);
  const cachedResponse = await cache.match(url);

  if (cachedResponse) {
    return cachedResponse;
  }

  const response = await fetch(url, options);
  const clonedResponse = response.clone();

  const headers = new Headers(clonedResponse.headers);
  headers.set("X-Cached-Timestamp", String(Date.now()));

  const responseWithTimestamp = new Response(clonedResponse.body, {
    status: clonedResponse.status,
    statusText: clonedResponse.statusText,
    headers,
  });

  await cache.put(url, responseWithTimestamp);

  return response;
};
