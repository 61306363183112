import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "settings" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "settings__login-button",
      onClick: $setup.login
    }, "Login"),
    _createElementVNode("div", {
      class: "settings__start-button",
      onClick: $setup.login
    }, "Start for free")
  ]))
}