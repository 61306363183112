import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/expanded-logo.svg'


const _hoisted_1 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      class: "header__logo",
      src: _imports_0,
      alt: "vertex-logo"
    }, null, -1)),
    (!$props.isEmptyLayout)
      ? (_openBlock(), _createBlock($setup["NavHeader"], {
          key: 0,
          navigation: $props.navigation
        }, null, 8, ["navigation"]))
      : _createCommentVNode("", true),
    (!$props.isEmptyLayout && !$props.isSubscriptionLayout)
      ? (_openBlock(), _createBlock($setup["HeaderSettings"], {
          key: 1,
          class: "header__settings"
        }))
      : _createCommentVNode("", true),
    ($props.isSubscriptionLayout)
      ? (_openBlock(), _createBlock($setup["HeaderSubscriptionSettings"], {
          key: 2,
          class: "header__settings"
        }))
      : _createCommentVNode("", true)
  ]))
}