import {
  SeqActionStateEnum,
  SeqActionTypeEnum,
  SeqActionUiStateEnum,
} from "@/enums/sequenceEnum";
import { PokerActionsEnum } from "@/poker-query-lib/enums/poker-actions.enum";

import type { ActionListingData } from "@/types/actionListingJson";
import type { StateInfo } from "@/types/general";
import type { PreflopSequenceMapRecord } from "@/types/sequence";
import type {
  SeqAction,
  SeqActionAny,
  SeqActionBoardCards,
  SeqActionCompound,
  SeqActionEmpty,
  SeqActionSingleUpCard,
  SeqActionSkipped,
  SeqActionStreet,
  SeqActionTerminated,
} from "@/types/seqAction";
import { isRoundEndingActionName } from "@/features/action/actionGeneral";
import type { UpCardsRecords } from "@/types/upCards";

export const getOpenAction = (
  data: ActionListingData | PreflopSequenceMapRecord
): SeqAction => {
  const { actionsList, stateInfo } = data;
  return {
    type: SeqActionTypeEnum.action,
    state: SeqActionStateEnum.toAct,
    uiState: SeqActionUiStateEnum.visible,
    sequence: "open",
    chosenAction: "",
    actionsList,
    stateInfo,
  };
};

export const getToActAction = (
  seqStr: string,
  data: ActionListingData | PreflopSequenceMapRecord
): SeqAction => {
  const { actionsList, stateInfo } = data;
  const isActionDiscard = "isDiscardAction" in data && data.isDiscardAction;
  return {
    type: isActionDiscard
      ? SeqActionTypeEnum.discardAction
      : SeqActionTypeEnum.action,
    state: SeqActionStateEnum.toAct,
    uiState: SeqActionUiStateEnum.visible,
    sequence: seqStr,
    chosenAction: "",
    actionsList,
    stateInfo,
  };
};

export const getBoardCardsAction = (
  boardCards: string[]
): SeqActionBoardCards => {
  return {
    type: SeqActionTypeEnum.boardCards,
    state: SeqActionStateEnum.performed,
    uiState: SeqActionUiStateEnum.visible,
    boardCards,
  };
};

export const getUpCardAction = (
  upCards: UpCardsRecords,
  heroCard?: string
): SeqActionSingleUpCard => {
  return {
    state: SeqActionStateEnum.performed,
    type: SeqActionTypeEnum.singleUpCard,
    uiState: SeqActionUiStateEnum.visible,
    upCards,
    ...(heroCard && {
      heroCard,
    }),
  };
};

export const getSkippedAction = (): SeqActionSkipped => {
  return {
    type: SeqActionTypeEnum.action,
    state: SeqActionStateEnum.skipped,
    uiState: SeqActionUiStateEnum.visible,
  };
};

export const getTerminatedAction = (
  seqStr: string,
  stateInfo: StateInfo
): SeqActionTerminated => {
  return {
    type: SeqActionTypeEnum.terminated,
    state: SeqActionStateEnum.performed,
    uiState: SeqActionUiStateEnum.visible,
    sequence: seqStr,
    stateInfo,
  };
};

export const getEmptyAction = (): SeqActionEmpty => {
  return {
    type: SeqActionTypeEnum.action,
    state: SeqActionStateEnum.empty,
    uiState: SeqActionUiStateEnum.visible,
  };
};

export const getEmptyActions = (numOfActions: number): SeqActionEmpty[] => {
  const result = [];
  for (let i = 0; i < numOfActions; i++) {
    result.push(getEmptyAction());
  }
  return result;
};

export const isFoldAction = (action: SeqActionAny) => {
  if (isActionWithData(action)) {
    return action.chosenAction.startsWith(PokerActionsEnum.FOLD);
  } else {
    return false;
  }
};

export const isRaiseOrBetAction = (
  action: SeqActionAny
): action is SeqAction => {
  if (isActionWithData(action)) {
    return (
      action.chosenAction.startsWith(PokerActionsEnum.RAISE) ||
      action.chosenAction.startsWith(PokerActionsEnum.BET)
    );
  } else {
    return false;
  }
};

export const isPreflopAction = (
  actionIdx: number,
  sequence: SeqActionAny[]
) => {
  const firstFlopIndex = sequence.findIndex(
    (item: SeqActionAny) =>
      isActionWithData(item) && isRoundEndingActionName(item.chosenAction)
  );
  return firstFlopIndex === -1 || actionIdx <= firstFlopIndex;
};

export const isBoardCardsAction = (
  action: SeqActionAny
): action is SeqActionBoardCards => {
  return action.type === SeqActionTypeEnum.boardCards;
};

export const isSingleUpCardAction = (
  action?: SeqActionAny
): action is SeqActionSingleUpCard => {
  if (action) {
    return action.type === SeqActionTypeEnum.singleUpCard;
  } else {
    return false;
  }
};

export const isDiscardAction = (action?: SeqActionAny): action is SeqAction => {
  if (action) {
    return action.type === SeqActionTypeEnum.discardAction;
  } else {
    return false;
  }
};

export const isToActAction = (action: SeqActionAny): action is SeqAction => {
  return action.state === SeqActionStateEnum.toAct;
};

export const isSkippedAction = (
  action: SeqActionAny
): action is SeqActionSkipped => {
  return action.state === SeqActionStateEnum.skipped;
};

export const isUiEmptyAction = (action: SeqActionAny): action is SeqAction => {
  return action.state === SeqActionStateEnum.uiEmpty;
};

export const isEmptyAction = (
  action: SeqActionAny
): action is SeqActionEmpty => {
  return action.state === SeqActionStateEnum.empty;
};

export const isTerminatedAction = (
  action?: SeqActionAny
): action is SeqActionTerminated => {
  if (action) {
    return action.type === SeqActionTypeEnum.terminated;
  } else {
    return false;
  }
};

export const isRoundEndingAction = (action?: SeqActionAny) => {
  if (action && isActionWithData(action)) {
    return isRoundEndingActionName(action.chosenAction);
  } else {
    return false;
  }
};

export const isUiHiddenAction = (action: SeqActionAny) => {
  return action.uiState === SeqActionUiStateEnum.hidden;
};

export const isActionWithData = (action: SeqActionAny): action is SeqAction => {
  const isValidActionType =
    action.type === SeqActionTypeEnum.action ||
    action.type === SeqActionTypeEnum.discardAction;

  const isValidActionState =
    action.state === SeqActionStateEnum.toAct ||
    action.state === SeqActionStateEnum.performed ||
    action.state === SeqActionStateEnum.uiEmpty;

  return isValidActionType && isValidActionState;
};

export const isStreetAction = (
  action: SeqActionAny
): action is SeqActionStreet => {
  return (
    action.type === SeqActionTypeEnum.action ||
    action.type === SeqActionTypeEnum.terminated
  );
};

export const isCompoundAction = (
  action: SeqActionAny
): action is SeqActionCompound => {
  return (
    isDiscardAction(action) ||
    isBoardCardsAction(action) ||
    isSingleUpCardAction(action)
  );
};
