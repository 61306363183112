import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
import HeaderComponent from "@/components/layout/HeaderComponent.vue";
import { useRoute } from "vue-router";
import routesName from "@/constants/routesName";


export default /*@__PURE__*/_defineComponent({
  __name: 'EmptyLayout',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const isEmptyLayout = computed(() => {
  return [routesName.login].includes(route.name as string);
});

const __returned__ = { route, isEmptyLayout, computed, HeaderComponent, get useRoute() { return useRoute }, get routesName() { return routesName } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})