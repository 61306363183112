import routesName from "@/constants/routesName";

import type { RouteLocationNormalizedGeneric } from "vue-router";
import type { SelectedGameStrategy } from "@/types/gameSettings";

export const shouldRedirectUnauthorizedUser = (
  to: RouteLocationNormalizedGeneric,
  hasAccessToken: string | null
) => {
  const isUnauthorizedUserPage = to.matched.some(
    (record) => record.meta.isUnauthorizedUserPage
  );
  return isUnauthorizedUserPage && hasAccessToken;
};

export const isGameSetupWithoutQuery = (to: RouteLocationNormalizedGeneric) => {
  return to.name === routesName.gameSetup && !to.query.redirect;
};

export const handleProtectedRoute = (
  to: RouteLocationNormalizedGeneric,
  gameStore: any,
  userStore: any
) => {
  const isRouteProtected = to.matched.some((record) => record.meta.isProtected);
  if (!isRouteProtected) {
    return {
      shouldRedirect: false,
      shouldContinue: false,
      shouldClearMatrix: false,
    };
  }

  const savedPreflopStrategies = gameStore.savedPreflopStrategies.length;
  const savedStudyStrategies = gameStore.savedStudyStrategies.length;
  const hasAccessToken = userStore.accessToken;
  const noSavedStrategies = checkNoSavedStrategies(
    to,
    savedPreflopStrategies,
    savedStudyStrategies
  );
  const hasConnection = !!to.query.connectionName;

  if (!hasAccessToken) {
    const redirectQuery = to.query ? to.fullPath : to.path;
    return {
      shouldRedirect: true,
      redirectName: routesName.login,
      redirectQuery,
      shouldClearMatrix: false,
      shouldContinue: false,
    };
  }

  if (noSavedStrategies && !hasConnection) {
    return {
      shouldRedirect: true,
      redirectName: routesName.gameSetup,
      redirectQuery: to.path,
      shouldClearMatrix: false,
      shouldContinue: false,
    };
  }

  if (noSavedStrategies && hasConnection) {
    return {
      shouldRedirect: false,
      shouldContinue: true,
      shouldClearMatrix: false,
    };
  }

  return {
    shouldRedirect: false,
    shouldContinue: false,
    shouldClearMatrix: true,
  };
};

export const checkNoSavedStrategies = (
  to: RouteLocationNormalizedGeneric,
  savedPreflopStrategies: SelectedGameStrategy[],
  savedStudyStrategies: SelectedGameStrategy[]
) =>
  (!savedPreflopStrategies && to.name === routesName.preflop) ||
  (!savedStudyStrategies && to.name === routesName.study);
