import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, mergeProps as _mergeProps, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        ($props.isOpen)
          ? (_openBlock(), _createElementBlock("div", _mergeProps({
              key: 0,
              class: "modal"
            }, _ctx.$attrs), [
              _createElementVNode("div", {
                class: "modal__backdrop",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emits('on-close')))
              }),
              _createElementVNode("div", {
                class: _normalizeClass(["modal__container", $props.modalClass])
              }, [
                ($props.isCloseButton)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "modal__close",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.emits('on-close')))
                    }))
                  : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ], 2)
            ], 16))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}