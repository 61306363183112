import { PokerTablePositionEnum } from "@/enums/pokerTableEnum";
import { PlayerStateEnum } from "@/enums/playerEnum";
import { PlayerList } from "@/entities/Player";
import type { PlayerWithAdditionalInfo } from "@/types/player";
import type { GameConfig } from "@/types/gameSettings";

export const changePlayerListForQuiz = (
  arr: PokerTablePositionEnum[],
  newBottomCenterIdx: number
) => {
  const bottomCenterIdx = arr.indexOf(PokerTablePositionEnum.bottomCenter);
  const copy = [...arr];
  const numShifts = newBottomCenterIdx - bottomCenterIdx;
  if (numShifts > 0) {
    const shiftedElements = copy.splice(-numShifts);
    copy.unshift(...shiftedElements);
  } else if (numShifts < 0) {
    const shiftedElements = copy.splice(0, -numShifts);
    copy.push(...shiftedElements);
  }
  return copy;
};

export const isPlayerFolded = (player: PlayerWithAdditionalInfo) => {
  return player.state === PlayerStateEnum.folded;
};

export const isPlayerActive = (player: PlayerWithAdditionalInfo) => {
  return player.state === PlayerStateEnum.active;
};

export const getGameSetupTablePlayers = (
  gameConfig: GameConfig,
  stack: null | string
) => {
  const players = new PlayerList(gameConfig).data;

  return players.map((player) => {
    return {
      ...player,
      isInteractive: false,
      normalizingUnit: gameConfig.normalizingUnit,
      stackWithContrib: {
        stack: stack ? Number(stack) : null,
        stackSuffix: "bb",
        contrib: 0,
      },
      state: PlayerStateEnum.regular,
    };
  });
};
