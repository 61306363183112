import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.navigation, (item) => {
      return (_openBlock(), _createBlock(_component_RouterLink, {
        class: _normalizeClass(["nav__link", { 'router-link-active': $setup.isSetupScreen(item.link) }]),
        key: item.slug,
        to: $setup.getLinkForRedirect(item.link)
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t(item.title)), 1)
        ]),
        _: 2
      }, 1032, ["class", "to"]))
    }), 128))
  ]))
}