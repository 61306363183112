import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
import { useRoute } from "vue-router";
import UnavailableOverlay from "@/components/common/UnavailableOverlay.vue";
import ToastFocusMode from "@/components/ui/ToastFocusMode.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import SubscriptionLayout from "@/layouts/SubscriptionLayout.vue";
import { useGameStore } from "@/stores/game";
import { useLayoutStore } from "@/stores/layout";
import { useKeyDown } from "@/composables/useKeyDown";
import { initSounds } from "@/features/pokerSounds";
import routesName from "@/constants/routesName";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const gameStore = useGameStore();
const layoutStore = useLayoutStore();

const isProtectedRoute = computed(() => {
  return route.meta.isProtected;
});

const isSubscriptionPages = computed(() => {
  return [routesName.product, routesName.pricing, routesName.faq].includes(
    route.name as string
  );
});
const isWithoutLayout = computed(() => {
  return route.meta.isWithoutLayout;
});
const isLoginPage = computed(() => {
  return route.name === routesName.login;
});

initSounds();
useKeyDown();

const __returned__ = { route, gameStore, layoutStore, isProtectedRoute, isSubscriptionPages, isWithoutLayout, isLoginPage, computed, get useRoute() { return useRoute }, UnavailableOverlay, ToastFocusMode, MainLayout, EmptyLayout, SubscriptionLayout, get useGameStore() { return useGameStore }, get useLayoutStore() { return useLayoutStore }, get useKeyDown() { return useKeyDown }, get initSounds() { return initSounds }, get routesName() { return routesName } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})