import { computed, ref, shallowRef } from "vue";
import { defineStore } from "pinia";
import { useParsedLibDataStore } from "@/stores/parsedLibData";
import { useGameStore } from "@/stores/game";
import pokerApi from "@/services/pokerApi";
import { PlayerList } from "@/entities/Player";
import { getMatrix } from "@/composables/useMatrixData";
import { useUpCards } from "@/composables/useUpCards";
import {
  getEmptyActions,
  getEmptyAction,
  getOpenAction,
  getSkippedAction,
  getTerminatedAction,
  getToActAction,
  isActionWithData,
  isEmptyAction,
  isFoldAction,
  isSkippedAction,
  isTerminatedAction,
  isUiEmptyAction,
  isToActAction,
  getUpCardAction,
  isSingleUpCardAction,
} from "@/features/sequence/action";
import { findAndSetConnectionName } from "@/features/gameSettings";
import { getSeqFromSeqStateReq } from "@/features/queryHandlers";
import {
  isCheckActionName,
  isFoldActionName,
} from "@/features/action/actionGeneral";
import {
  getActionIdxOnTablePlayerClick,
  isRoundOrGameEnd,
} from "@/features/sequence/utils";
import { getPlayersWithAdditionalInfo } from "@/features/playersWithAdditionalInfo";
import { isPlayerActive } from "@/features/pokerPlayers";
import { getSingleCardPerPlayer } from "@/features/cards/upCards";
import { getSeqWithGroups } from "@/features/sequence/widget/groups";
import { isSeqWidgetStreetActionGroup } from "@/features/sequence/widget/guards";

import { SeqActionStateEnum, SeqActionUiStateEnum } from "@/enums/sequenceEnum";

import type { SeqAction, SeqActionAny } from "@/types/seqAction";
import type {
  ActionListingData,
  ActionListingReqParams,
} from "@/types/actionListingJson";
import type {
  PerformActionParams,
  PreflopSequenceMapRecord,
  SequenceStoreInitParams,
} from "@/types/sequence";
import type { SelectedGameStrategy } from "@/types/gameSettings";
import type { UpCardsRecords } from "@/types/upCards";
import type { SeqWidgetStreetActionGroup } from "@/types/seqWidget";
import type { PlayerWithAdditionalInfo } from "@/types/player";

export const useSequenceStore = defineStore(
  "sequence",
  () => {
    const seqDictionary = shallowRef<Record<string, PreflopSequenceMapRecord>>(
      {}
    );
    const sequence = ref<SeqActionAny[]>([]);
    const seqPointer = ref(-1);
    const playersNum = ref(0);
    const playerList = ref<PlayerList>();
    const isFetchingActionListing = ref(false);
    const isFetchingSeqMap = ref(false);

    const gameStore = useGameStore();
    const { setMatrixData, clearMatrixData } = useParsedLibDataStore();
    const {
      addSingleUpCardToEachPlayer,
      closeUpCardsModal,
      confirmUpCardsModal,
      getUpCardsForRequest,
      initUpCards,
      isOpenUpCardsModal,
      openUpCardsModal,
      resetUpCards,
      setUpCards,
      upCards,
      upCardsActionIdx,
      upCardsActiveAction,
    } = useUpCards(seqPointer, sequence);

    const getOpenData = () => {
      return Object.keys(seqDictionary.value).includes("open")
        ? seqDictionary.value.open
        : sequence.value.length
        ? {
            actionsList: (sequence.value.find(isActionWithData) as SeqAction)
              .actionsList,
            stateInfo: (sequence.value.find(isActionWithData) as SeqAction)
              .stateInfo,
          }
        : null;
    };
    const activePlayerUpCards = computed(() => {
      if (playersWithAdditionalInfo.value && upCards.value) {
        const activePlayer =
          playersWithAdditionalInfo.value.find(isPlayerActive);
        if (activePlayer) {
          return upCards.value[activePlayer.name];
        } else {
          return [];
        }
      } else {
        return [];
      }
    });
    const activeAction = computed(() => {
      return sequence.value[seqPointer.value] as SeqAction;
    });
    const numCompletedActions = computed(() => {
      return sequence.value.filter((action) => !isEmptyAction(action)).length;
    });
    const isAllowedNextAction = computed(() => {
      return seqPointer.value < numCompletedActions.value - 1;
    });
    const isAllowedPrevAction = computed(() => {
      if (isSingleUpCardAction(sequence.value[0]) && seqPointer.value === 1) {
        return false;
      } else {
        return seqPointer.value > 0;
      }
    });
    const isPreflopEnd = computed(() => {
      return isTerminatedAction(activeAction.value);
    });
    const playersWithAdditionalInfo = computed(() => {
      return getPlayersWithAdditionalInfo(
        playerList.value?.data,
        sequence.value,
        seqPointer.value
      );
    });
    const isActionOnFirstLevel = computed(() => {
      const arr = getSeqWithGroups(sequence.value);
      const activeGroup = arr.find(
        (item) =>
          isSeqWidgetStreetActionGroup(item) &&
          item.data.some((action) => action.order === seqPointer.value)
      ) as SeqWidgetStreetActionGroup;
      if (activeGroup) {
        const firstActionInGroupOrder = activeGroup.data[0].order;
        return seqPointer.value - firstActionInGroupOrder < playersNum.value;
      } else {
        return false;
      }
    });
    const isFirstActionOnFirstLevel = computed(() => {
      const arr = getSeqWithGroups(sequence.value);
      const activeGroup = arr.find(
        (item) =>
          isSeqWidgetStreetActionGroup(item) &&
          item.data.some((action) => action.order === seqPointer.value)
      ) as SeqWidgetStreetActionGroup;
      if (activeGroup) {
        const firstActionInGroupOrder = activeGroup.data[0].order;
        return seqPointer.value === firstActionInGroupOrder;
      } else {
        return false;
      }
    });
    const isLastActionInFirstLevel = computed(() => {
      const arr = getSeqWithGroups(sequence.value);

      const activeGroup = arr.find(
        (item) =>
          isSeqWidgetStreetActionGroup(item) &&
          item.data.some((action) => action.order === seqPointer.value)
      ) as SeqWidgetStreetActionGroup;

      if (activeGroup) {
        const firstRow = activeGroup.data.slice(0, playersNum.value);
        const lastActionInFirstRowOrder = firstRow[firstRow.length - 1].order;

        return seqPointer.value === lastActionInFirstRowOrder;
      }
      return false;
    });

    const checkPreviousFoldOfPlayer = (
      actionOrder: number,
      numOfPlayers: number,
      arr: SeqActionAny[]
    ) => {
      const getPlayerPrevActions = (_: SeqActionAny, index: number) =>
        index % numOfPlayers === actionOrder % numOfPlayers;
      return arr.filter(getPlayerPrevActions).some(isFoldAction);
    };
    const shiftSeqPointerNext = () => {
      const currentAction = () => sequence.value[seqPointer.value];
      if (isActionWithData(activeAction.value)) {
        changeActionState(SeqActionStateEnum.performed);
      }
      seqPointer.value++;
      currentAction().uiState = SeqActionUiStateEnum.visible;
      if (isSkippedAction(activeAction.value)) {
        shiftSeqPointer();
      }
      if (isActionWithData(activeAction.value)) {
        changeActionState(SeqActionStateEnum.toAct);
      }
      if (isTerminatedAction(activeAction.value)) {
        changeActionState(SeqActionStateEnum.performed);
      }
    };
    const shiftSeqPointerPrev = () => {
      const currentAction = () => sequence.value[seqPointer.value];
      if (isActionOnFirstLevel.value && !isFirstActionOnFirstLevel.value) {
        if (
          isActionWithData(activeAction.value) ||
          isTerminatedAction(activeAction.value)
        ) {
          changeActionState(SeqActionStateEnum.uiEmpty);
        }
        seqPointer.value--;
        if (isSkippedAction(activeAction.value)) {
          shiftSeqPointer(false);
        }
        if (isActionWithData(activeAction.value)) {
          changeActionState(SeqActionStateEnum.toAct);
        }
      } else {
        currentAction().uiState = SeqActionUiStateEnum.hidden;
        seqPointer.value--;
        if (isSkippedAction(activeAction.value)) {
          shiftSeqPointer(false);
        }
        if (isActionWithData(activeAction.value)) {
          changeActionState(SeqActionStateEnum.toAct);
        }
      }
    };
    const shiftSeqPointer = (isNext = true) => {
      clearMatrixData();
      if (isNext) {
        shiftSeqPointerNext();
      } else {
        shiftSeqPointerPrev();
      }
    };
    const setSeqAction = (action: SeqActionAny, idx = seqPointer.value) => {
      sequence.value[idx] = action;
    };
    const addActionToSeq = (action: SeqActionAny) => {
      sequence.value.push(action);
    };
    const changeActionName = (actionName: string) => {
      const currentAction = sequence.value[seqPointer.value];
      if (isActionWithData(currentAction)) {
        currentAction.chosenAction = actionName;
      }
    };
    const changeActionState = (actionState: SeqActionStateEnum) => {
      sequence.value[seqPointer.value].state = actionState;
    };
    const addActionToSeqAndShiftPointer = (action: SeqActionAny) => {
      addActionToSeq(action);
      shiftSeqPointer();
    };
    const shiftPointerAndSetAction = (action: SeqActionAny) => {
      setSeqAction(action, seqPointer.value + 1);
      shiftSeqPointer();
    };
    const handleCloseUpCardsModal = () => {
      closeUpCardsModal();
      upCardsActionIdx.value = -1;
      if (!isTerminatedAction(activeAction.value)) {
        changeActionState(SeqActionStateEnum.toAct);
      }
    };
    const handleConfirmUpCardsModal = (upCardsRecords: UpCardsRecords) => {
      confirmUpCardsModal(upCardsRecords);
      while (upCardsActionIdx.value + 1 < seqPointer.value) {
        shiftSeqPointer(false);
      }
      sequence.value.splice(upCardsActionIdx.value + 2);
      setSeqAction(getUpCardAction(upCardsRecords), upCardsActionIdx.value);
      upCardsActionIdx.value = -1;
      const activeActionSequence = activeAction.value.sequence;
      getActionListing(activeActionSequence, {
        upCards: getUpCardsForRequest(),
      }).then((data) => {
        setSeqAction(getToActAction(activeActionSequence, data));
        sequence.value.push(...getEmptyActions(playersNum.value - 1));
      });
    };
    const onAsideTablePlayerClick = (player: PlayerWithAdditionalInfo) => {
      const actionIdx = getActionIdxOnTablePlayerClick({
        activeAction: activeAction.value,
        player,
        sequence: sequence.value,
        seqPointer: seqPointer.value,
        playersWithAdditionalInfo: playersWithAdditionalInfo.value,
      });
      if (actionIdx) {
        performAction({
          idx: actionIdx,
          actionName: "",
        });
      }
    };
    const handlePerformAction = (
      seqStr: string,
      data: ActionListingData | PreflopSequenceMapRecord
    ) => {
      if (isActionOnFirstLevel.value && !isLastActionInFirstLevel.value) {
        shiftPointerAndSetAction(getToActAction(seqStr, data));
        return;
      }
      handleSkipActions();
      addActionToSeqAndShiftPointer(getToActAction(seqStr, data));
    };
    const performRegularAction = async (seqStr: string) => {
      if (seqDictionary.value[seqStr]) {
        handlePerformAction(seqStr, seqDictionary.value[seqStr]);
        return Promise.resolve();
      } else {
        return getActionListing(seqStr, {
          upCards: getUpCardsForRequest(),
        }).then((data) => {
          handlePerformAction(seqStr, data);
        });
      }
    };
    const performEndAction = async (seqStr: string) => {
      if (isActionOnFirstLevel.value && !isLastActionInFirstLevel.value) {
        shiftPointerAndSetAction(
          getTerminatedAction(seqStr, activeAction.value.stateInfo)
        );
      } else {
        handleSkipActions();
        addActionToSeqAndShiftPointer(
          getTerminatedAction(seqStr, activeAction.value.stateInfo)
        );
      }
    };
    const performActionByActionName = async ({
      actionName,
      idx = seqPointer.value,
      resetCurrentActionStateToToAct = false,
    }: PerformActionParams) => {
      clearMatrixData();
      if (isFetchingActionListing.value) {
        return;
      }
      if (resetCurrentActionStateToToAct || idx !== seqPointer.value) {
        // Action when breadcrumb clicked and Action from tooltip
        while (idx < seqPointer.value) {
          shiftSeqPointer(false);
        }
        cutSeqAndSetEmptyActions();
      }

      const seqStr =
        seqForRequest.value === "open"
          ? actionName
          : seqForRequest.value + actionName;
      if (!resetCurrentActionStateToToAct) {
        changeActionName(actionName);
        changeActionState(SeqActionStateEnum.performed);
        cutSeqAndSetEmptyActions();
        if (isRoundOrGameEnd(actionName)) {
          await performEndAction(seqStr);
        } else {
          await performRegularAction(seqStr);
          cutSeqAndSetEmptyActions();
        }
      }
    };
    const performAction = async ({
      actionName,
      idx = seqPointer.value,
      resetCurrentActionStateToToAct = false,
    }: PerformActionParams) => {
      const action = sequence.value[idx];
      if (isActionWithData(action) && !isUiEmptyAction(action)) {
        await performActionByActionName({
          actionName,
          idx,
          resetCurrentActionStateToToAct,
        });
      } else if (isEmptyAction(action) || isUiEmptyAction(action)) {
        for (let i = seqPointer.value; i < idx; i++) {
          await performActionByActionName({
            actionName:
              activeAction.value.actionsList.find(
                (actionNameFromList) =>
                  isFoldActionName(actionNameFromList) ||
                  isCheckActionName(actionNameFromList)
              ) || "",
          });
        }
      }
    };
    const handleSkipActions = () => {
      let nextPlayerIdx = seqPointer.value + 1;
      while (
        checkPreviousFoldOfPlayer(
          nextPlayerIdx,
          playersNum.value,
          sequence.value
        )
      ) {
        addActionToSeq(getSkippedAction());
        nextPlayerIdx++;
      }
    };
    const cutSeqAndSetEmptyActions = () => {
      sequence.value.splice(seqPointer.value + 1);
      if (isActionOnFirstLevel.value) {
        const seqArr = getSeqWithGroups(sequence.value);
        const activeGroup = seqArr.find(
          (item) =>
            isSeqWidgetStreetActionGroup(item) &&
            item.data.some((action) => action.order === seqPointer.value)
        ) as SeqWidgetStreetActionGroup;
        const firstLevelInGroup = activeGroup.data.slice(0, playersNum.value);
        const indexOfActiveItem = firstLevelInGroup.findIndex(
          (item) => item.order === seqPointer.value
        );
        const amountOfEmpties = playersNum.value - (indexOfActiveItem + 1);
        for (let i = 0; i < amountOfEmpties; i++) {
          addActionToSeq(getEmptyAction());
        }
      }
    };
    const setInitSeqState = (params?: SequenceStoreInitParams) => {
      clearMatrixData();
      if (gameStore.configByConnectionName) {
        const gameConfig = gameStore.configByConnectionName;
        const { numPlayers } = gameConfig;
        playersNum.value = numPlayers;
        playerList.value = new PlayerList(gameConfig);
        const openData = getOpenData();
        sequence.value = [];
        seqPointer.value = 0;
        if (gameStore.isCurrentGameStud && upCards.value && !params?.upCards) {
          resetUpCards();
          sequence.value.push(getUpCardAction(upCards.value));
          seqPointer.value = 1;
        } else {
          initUpCards();
        }
        if (!params?.seq && openData) {
          if (gameStore.isCurrentGameStud && params?.upCards) {
            const initialUpCardsFromString = getSingleCardPerPlayer(
              params.upCards,
              gameConfig.playerNames
            );
            initUpCards(initialUpCardsFromString, {
              playerNames: gameConfig.playerNames,
            });
            sequence.value.push(
              getUpCardAction(upCards.value as UpCardsRecords)
            );
            seqPointer.value = 1;
          }
          setSeqAction(getOpenAction(seqDictionary.value.open));
          sequence.value.push(...getEmptyActions(numPlayers - 1));
        } else if (params?.seq) {
          if (gameStore.isCurrentGameStud && params?.upCards) {
            initUpCards(params.upCards, {
              playerNames: gameConfig.playerNames,
            });
          }
          sequence.value = [...params.seq];
          seqPointer.value = params.seq.findIndex(
            (action) => isToActAction(action) || isTerminatedAction(action)
          );
        }
      }
    };
    const initPreflopSeqStore = async (params?: SequenceStoreInitParams) => {
      if (!params?.seq) {
        await Promise.all([
          getActionListing("open", {
            upCards: params?.upCards,
          }).then(() => {
            setInitSeqState(params);
          }),
          fetchSeqDict(),
        ]);
      } else {
        fetchSeqDict().then(() => {
          setInitSeqState(params);
        });
      }
    };
    const getActionListing = async (
      seqStr: string,
      reqParams?: ActionListingReqParams
    ) => {
      const params = {
        upCards: reqParams?.upCards ? reqParams?.upCards : undefined,
      };
      isFetchingActionListing.value = true;
      return pokerApi
        .fetchActionListingJson(seqStr, params)
        .then((res) => {
          seqDictionary.value[seqStr] = {
            actionsList: res.data.data.actionsList,
            stateInfo: res.data.data.stateInfo,
          };
          return res.data.data;
        })
        .finally(() => {
          isFetchingActionListing.value = false;
        });
    };
    const fetchSeqDict = async () => {
      if (gameStore.isCurrentGameStud) {
        return;
      }
      isFetchingSeqMap.value = true;
      pokerApi
        .fetchPreflopSequenceJsonMap()
        .then((res) => {
          seqDictionary.value = res.data.data;
        })
        .finally(() => {
          isFetchingSeqMap.value = true;
        });
    };
    const seqForRequest = computed(() => {
      return activeAction.value ? activeAction.value.sequence : "open";
    });
    const fetchAndSetMatrix = () => {
      const params = {
        betSequence: seqForRequest.value,
        ...(gameStore.isCurrentGameStud && { upCards: getUpCardsForRequest() }),
      };
      getMatrix(params.betSequence, {
        upCards: params.upCards,
      }).then(() => {
        setMatrixData(true);
      });
    };
    const settingsChangeHandler = async (strategy: SelectedGameStrategy) => {
      await findAndSetConnectionName(true);
      await initPreflopSeqStore({
        upCards: "upCards" in strategy ? strategy.upCards : undefined,
      });
    };
    const setSeqStateFromQuery = (
      seq: string,
      options?: { upCards?: string }
    ) => {
      if (seq !== "open") {
        pokerApi
          .fetchSeqStateHistory(seq, options)
          .then(async (res) => {
            const newSeq = getSeqFromSeqStateReq(res.data.data, {
              isPreflop: true,
              withSkippedAndEmptyActions: true,
              upCardsOptions: {
                upCards: options?.upCards,
                playerNames: gameStore.configByConnectionName?.playerNames,
              },
            });
            await initPreflopSeqStore({ seq: newSeq, ...options });
          })
          .catch(async () => {
            await initPreflopSeqStore(options);
            gameStore.updatePageQueries(seqForRequest.value);
          });
      } else {
        initPreflopSeqStore();
      }
    };

    return {
      activeAction,
      activePlayerUpCards,
      fetchAndSetMatrix,
      initPreflopSeqStore,
      isAllowedNextAction,
      isAllowedPrevAction,
      isFetchingActionListing,
      isFetchingSeqMap,
      isPreflopEnd,
      onAsideTablePlayerClick,
      performAction,
      playersWithAdditionalInfo,
      seqForRequest,
      seqPointer,
      sequence,
      setInitSeqState,
      setSeqStateFromQuery,
      settingsChangeHandler,
      shiftSeqPointer,
      isActionOnFirstLevel,
      upCardsModule: ref({
        activeAction: upCardsActiveAction,
        addSingleCardToEachPlayer: addSingleUpCardToEachPlayer,
        cards: upCards,
        getCardsForRequest: getUpCardsForRequest,
        setCards: setUpCards,
        upCardsActionIdx,
        cardsModal: {
          close: handleCloseUpCardsModal,
          confirm: handleConfirmUpCardsModal,
          isOpen: isOpenUpCardsModal,
          open: openUpCardsModal,
        },
      }),
    };
  },
  {
    share: {
      enable: false,
    },
  }
);
