import tinycolor from "tinycolor2";
import { isWhiteTextReadableOnBackground } from "@/utils/colors";
import {
  getRaisePercentOrBb,
  isRaiseValueAllIn,
} from "@/features/action/actionValue";
import type { StateInfo } from "@/types/general";
import {
  isDiscardActionName,
  isRaiseOrBetActionName,
} from "@/features/action/actionGeneral";

interface ActionLabelOptions {
  allInWithoutSize?: boolean;
  allInWithoutTitle?: boolean;
  fullDrawActionName?: boolean;
  fullStandPatName?: boolean;
  isPreflop?: boolean;
  isSeqAction?: boolean;
  isToActAction?: boolean;
  lowercaseActionName?: boolean;
  numOfCardsToDiscard?: number;
  onlyValue?: boolean;
  shortActionName?: boolean;
  shortDrawActionName?: boolean;
  shortRaiseBetActionName?: boolean;
  stateInfo?: StateInfo;
  withoutActionValue?: boolean;
}

export const allInLabel = "All-In";
const shortAllInLabel = "AI";
const shortStandPatLabel = "Pat";
const longStandPatLabel = "Stand pat";
export const actionLabelMap: Record<string, string> = {
  b: "Bet",
  c: "Call",
  terminated: "End",
  f: "Fold",
  k: "Check",
  empty: "",
  r: "Raise",
  skipped: "",
  toAct: "To Act",
  d: "D",
};
export const lowercaseActionLabelMap: Record<string, string> = {
  b: "bet",
  c: "call",
  f: "fold",
  k: "check",
  r: "raise",
  d: "draw",
};

export const getLongDiscardActionName = (numberOfCards: number) => {
  return `Draw ${numberOfCards}`;
};

export const getActionLabelTextStyle = (
  rgbString: string,
  options?: {
    frequency: number;
    frequencyThreshold: number;
  }
) => {
  const isReadable = !isWhiteTextReadableOnBackground(rgbString);
  const luminance = tinycolor(rgbString).getLuminance();
  const isFrequencyHigh =
    options && options.frequency > options.frequencyThreshold;
  const isBlackText = luminance > 0.6;
  const applyBlackText = options
    ? isBlackText && options.frequency > options.frequencyThreshold
    : isBlackText;
  const defaultConditionForShadow = isReadable && luminance < 0.6;
  const applyTextWithShadow = options
    ? isReadable && !(isBlackText && isFrequencyHigh)
    : defaultConditionForShadow;

  return {
    "black-text": applyBlackText,
    "text-with-shadow": applyTextWithShadow,
  };
};

const getAllInLabel = (
  action: string,
  options: ActionLabelOptions,
  isPreflop: boolean,
  stateInfo: StateInfo
): string => {
  let label: string | string[] = `${getRaisePercentOrBb(
    action,
    isPreflop,
    stateInfo
  )}`;
  if (options.allInWithoutTitle) {
    label = label.split(" ")[1];
  }
  if (options.allInWithoutSize) {
    label = allInLabel;
  }
  if (options.shortActionName || options.shortRaiseBetActionName) {
    label = [shortAllInLabel, ...label.split(" ").slice(1)].join(" ");
  }
  return label;
};
const getInitialActionLabel = (
  action: string,
  options?: ActionLabelOptions
): string => {
  if (options?.onlyValue) {
    return "";
  }
  if (options?.lowercaseActionName) {
    return lowercaseActionLabelMap[action[0]] || "";
  }
  if (isDiscardActionName(action)) {
    return getDiscardActionLabel(action, options);
  }
  if (options?.isSeqAction) {
    return actionLabelMap[action];
  }
  return actionLabelMap[action[0]];
};
const getDiscardActionLabel = (
  action: string,
  options?: ActionLabelOptions
) => {
  if (options?.shortDrawActionName) {
    return action[1];
  }
  if (action === "d0" && options?.fullStandPatName) {
    return longStandPatLabel;
  }
  if (action === "d0") {
    return shortStandPatLabel;
  }
  if (options?.fullDrawActionName && options?.numOfCardsToDiscard) {
    return getLongDiscardActionName(options.numOfCardsToDiscard);
  }
  return action.charAt(0).toUpperCase() + action.slice(1);
};
const getFinalActionLabelWithStateInfo = (
  action: string,
  options: ActionLabelOptions,
  finalActionLabel: string
): string => {
  const { stateInfo, isPreflop } = options;

  if (stateInfo && isPreflop !== undefined && isRaiseOrBetActionName(action)) {
    if (isRaiseValueAllIn(action, isPreflop, stateInfo)) {
      finalActionLabel = getAllInLabel(action, options, isPreflop, stateInfo);
    } else if (!options.withoutActionValue) {
      const actionValue = getRaisePercentOrBb(action, isPreflop, stateInfo);
      const isActionValueIsPercent = typeof actionValue === "number";
      finalActionLabel = `${finalActionLabel} ${actionValue}${
        isActionValueIsPercent ? "%" : ""
      }`;
      if (options.onlyValue) {
        finalActionLabel = `${actionValue}${isActionValueIsPercent ? "%" : ""}`;
      }
    }
  }

  return finalActionLabel;
};

export const getActionLabel = (
  action: string,
  options?: ActionLabelOptions
): string => {
  if (options?.isToActAction) {
    return actionLabelMap.toAct;
  }
  let finalActionLabel = getInitialActionLabel(action, options);

  if (options) {
    if (options.shortRaiseBetActionName && isRaiseOrBetActionName(action)) {
      finalActionLabel = finalActionLabel[0];
    }

    if (options.shortActionName) {
      finalActionLabel = finalActionLabel[0];
    }

    if (
      options.stateInfo &&
      options.isPreflop !== undefined &&
      isRaiseOrBetActionName(action)
    ) {
      finalActionLabel = getFinalActionLabelWithStateInfo(
        action,
        options,
        finalActionLabel
      );
    }
  }

  return finalActionLabel;
};
