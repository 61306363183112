import { defineComponent as _defineComponent } from 'vue'
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderSubscriptionSettings',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();

const login = () => {
  return router.push("/login");
};

const __returned__ = { router, login, get useRouter() { return useRouter } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})