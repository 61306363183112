import { contribForPlayer, stackForLastCall } from "@/features/sequence/utils";
import {
  isDrawGameCategory,
  isStudGameCategory,
} from "@/features/gameSettings";
import { isActionWithData } from "@/features/sequence/action";
import { useGameStore } from "@/stores/game";
import { PlayerCardTypeEnum } from "@/enums/playerEnum";
import type {
  PlayerCard,
  PlayerWithAdditionalInfo,
  PokerTablePlayerBox,
  PokerTablePlayerWithStyles,
} from "@/types/player";
import type { StateInfo } from "@/types/general";
import type { GameCategoryEnum } from "@/enums/gameEnum";
import type { GameConfig } from "@/types/gameSettings";
import type { SeqActionAny } from "@/types/seqAction";

interface PlayerDataWithStylesCardSettings {
  width: number;
  heightRatio: number;
}

interface GetCardLeftPosForStudGameParams {
  card: PlayerCard;
  cardIdx: number;
  cardListBoxWidth: number;
  cardWidth: number;
  numCards: number;
  numRegularCards: number;
}
interface GetCardLeftPosForDrawGameParams {
  allCardsWidth: number;
  cardIdx: number;
  cardListBoxWidth: number;
  numCards: number;
  playerCardList: PlayerCard[];
  regularCardWidth: number;
}
interface PlayerDataWithStylesParams {
  gameCategory?: GameCategoryEnum | null;
  player: PlayerWithAdditionalInfo;
  playerCardStyles: PlayerDataWithStylesCardSettings;
  playerStyles: PokerTablePlayerBox;
}

const quizPageActionButtonData = {
  fontSize: {
    max: 24,
    min: 16,
  },
  height: {
    max: 66,
    min: 44,
  },
};
const quizPageHandCardData = {
  fontSize: {
    max: 66,
    min: 38,
  },
  width: {
    max: 64,
    min: 34,
  },
  heightRatio: 1.375,
};

export const getPlayerStackWithContrib = (
  playerIdx: number,
  stateInfo: StateInfo | undefined,
  isCallEnd: boolean
) => {
  if (stateInfo) {
    const { remainingStack, roundContrib } = stateInfo;
    if (isCallEnd && playerIdx === stateInfo.playerIndex) {
      return {
        stack: stackForLastCall(stateInfo, playerIdx),
        contrib: contribForPlayer(stateInfo, playerIdx),
      };
    } else {
      return {
        stack: remainingStack[playerIdx],
        contrib: roundContrib[playerIdx],
      };
    }
  }
  return {
    stack: null,
    contrib: 0,
  };
};

export const actionButtonsStyles = (
  MAX_POKER_TABLE_WIDTH: number,
  quizPokerTableRect: DOMRectReadOnly | null
) => {
  if (!quizPokerTableRect) {
    return {
      fontSize: "",
      height: "",
    };
  }
  const { fontSize, height } = quizPageActionButtonData;
  const fontSizeRatio = MAX_POKER_TABLE_WIDTH / fontSize.max;
  const heightRatio = MAX_POKER_TABLE_WIDTH / height.max;

  return {
    fontSize: `${Math.round(
      Math.max(quizPokerTableRect.width / fontSizeRatio, fontSize.min)
    )}px`,
    height: `${Math.round(
      Math.max(quizPokerTableRect.width / heightRatio, height.min)
    )}px`,
  };
};

export const handCardStyles = (
  MAX_POKER_TABLE_WIDTH: number,
  quizPokerTableRect: DOMRectReadOnly | null
) => {
  if (!quizPokerTableRect) {
    return {
      fontSize: "",
      height: "",
      transition: "",
      width: "",
    };
  }
  const { width, fontSize, heightRatio } = quizPageHandCardData;
  const fontSizeRatio = MAX_POKER_TABLE_WIDTH / fontSize.max;
  const widthRatio = MAX_POKER_TABLE_WIDTH / width.max;
  const newWidth = Math.max(width.min, quizPokerTableRect.width / widthRatio);
  return {
    fontSize: `${Math.round(
      Math.max(quizPokerTableRect.width / fontSizeRatio, fontSize.min)
    )}px`,
    height: `${Math.round(newWidth * heightRatio)}px`,
    transition: "none",
    width: `${Math.round(newWidth)}px`,
  };
};

export const getPLayerCardDynamicWidth = (
  MAX_POKER_TABLE_WIDTH: number,
  quizPokerTableRect: DOMRectReadOnly | null,
  cardData: { max: number; min: number }
) => {
  if (!quizPokerTableRect) {
    return 0;
  } else {
    const { max, min } = cardData;
    const widthRatio = MAX_POKER_TABLE_WIDTH / max;
    const newWidth = Math.max(min, quizPokerTableRect.width / widthRatio);
    return Math.round(newWidth);
  }
};

const getCardLeftPosForStudGame = (data: GetCardLeftPosForStudGameParams) => {
  const {
    cardListBoxWidth,
    numRegularCards,
    cardWidth,
    cardIdx,
    card,
    numCards,
  } = data;
  const numUpCards = numCards - numRegularCards;
  const maxRegularCardCompressionInPercent = 70;
  const minWidthOfRegularCard = Math.max(
    cardWidth * (1 - maxRegularCardCompressionInPercent / 100),
    (cardListBoxWidth - numUpCards * cardWidth) / numRegularCards
  );
  if (card.type === PlayerCardTypeEnum.regular) {
    return cardIdx > 1
      ? cardListBoxWidth - cardWidth
      : cardIdx * minWidthOfRegularCard;
  } else {
    const widthForUpCards =
      cardListBoxWidth - minWidthOfRegularCard * numRegularCards;
    const availableWidth = numUpCards * cardWidth;
    const upCardOffset =
      availableWidth <= widthForUpCards
        ? 0
        : (availableWidth - widthForUpCards) / (numUpCards - 1);

    return (
      (cardIdx - 2) * (cardWidth - upCardOffset) + minWidthOfRegularCard * 2
    );
  }
};
const getCardLeftPosForDrawGame = (data: GetCardLeftPosForDrawGameParams) => {
  const {
    allCardsWidth,
    cardIdx,
    cardListBoxWidth,
    numCards,
    playerCardList,
    regularCardWidth,
  } = data;
  const gapOverlap = (allCardsWidth - cardListBoxWidth) / (numCards - 1);
  let leftPos = 0;

  for (let i = 0; i < cardIdx; i++) {
    leftPos += getCardWidthByType(playerCardList[i], regularCardWidth);

    if (i < playerCardList.length - 1) {
      leftPos -= gapOverlap;
    }
  }

  return leftPos;
};
const getCardWidthByType = (card: PlayerCard, defaultCardWidth: number) => {
  if (card.type === PlayerCardTypeEnum.discard) {
    return Math.round(defaultCardWidth + (defaultCardWidth * 27.2) / 100);
  }
  return defaultCardWidth;
};

export const playerDataWithStyles = (
  data: PlayerDataWithStylesParams
): PokerTablePlayerWithStyles => {
  const { player, playerCardStyles, playerStyles, gameCategory } = data;
  const gameStore = useGameStore();
  const cardListBoxLeftRightOffsets = 10;

  const isCurrentGameStud = gameCategory
    ? isStudGameCategory(gameCategory)
    : gameStore.isCurrentGameStud;
  const isCurrentGameDraw = gameCategory
    ? isDrawGameCategory(gameCategory)
    : gameStore.isCurrentGameDraw;

  const getCardLeftPos = (card: PlayerCard, cardIdx: number) => {
    const rightCardOffset = 1;
    const cardListBoxWidth =
      parseInt(playerStyles.player.width) - cardListBoxLeftRightOffsets;
    const cardWidth = playerCardStyles.width;
    const numCards = player.cardList.length;
    const allCardsWidth = player.cardList.reduce(
      (acc, playerCard) => acc + getCardWidthByType(playerCard, cardWidth),
      0
    );
    const remainingSpace = cardListBoxWidth - allCardsWidth;
    const numRegularCards = player.cardList.filter(
      (playerCard) => playerCard.type === PlayerCardTypeEnum.regular
    ).length;

    if (remainingSpace - numCards * rightCardOffset >= 0) {
      const startPos = (cardListBoxWidth - cardWidth * numCards) / 2;
      return (
        startPos - rightCardOffset + cardIdx * (cardWidth + rightCardOffset)
      );
    } else {
      if (isCurrentGameStud) {
        return getCardLeftPosForStudGame({
          card,
          cardIdx,
          cardListBoxWidth,
          cardWidth,
          numCards,
          numRegularCards,
        });
      }
      if (isCurrentGameDraw) {
        return getCardLeftPosForDrawGame({
          allCardsWidth,
          cardIdx,
          cardListBoxWidth,
          numCards,
          playerCardList: player.cardList,
          regularCardWidth: cardWidth,
        });
      }
      return `${cardIdx * (cardWidth + remainingSpace / (numCards - 1))}`;
    }
  };
  const getCardTopPosition = (card: PlayerCard) => {
    if (isCurrentGameStud) {
      if (card.type === PlayerCardTypeEnum.upCard) {
        return "4px";
      } else {
        return "10px";
      }
    } else if (isCurrentGameDraw) {
      if (card.type === PlayerCardTypeEnum.discard) {
        return "-2px";
      } else {
        return "6px";
      }
    } else {
      return "6px";
    }
  };
  const getCardZIndex = (card: PlayerCard, idx: number) => {
    if (gameStore.isCurrentGameStud && idx === 6 && card.isBack) {
      return -1;
    }
    return 1;
  };

  const extendedCardList = player.cardList.map((card, idx) => {
    const leftPos = getCardLeftPos(card, idx);
    return {
      heightRatio: playerCardStyles.heightRatio,
      isBack: card.isBack,
      left: leftPos ? `${leftPos}px` : "0",
      top: getCardTopPosition(card),
      type: card.type,
      value: card.value,
      width: `${getCardWidthByType(card, playerCardStyles.width)}px`,
      zIndex: getCardZIndex(card, idx),
    };
  });

  const cardBoxStyles = () => {
    return {
      height: `${Math.floor(
        playerCardStyles.width * playerCardStyles.heightRatio
      )}px`,
      width: `${
        parseInt(playerStyles.player.width) - cardListBoxLeftRightOffsets
      }px`,
    };
  };

  return {
    ...player,
    styles: {
      cardBoxStyles: cardBoxStyles(),
      cardList: extendedCardList,
      chip: playerStyles.chip,
      dealerBtn: playerStyles.dealerBtn,
      player: playerStyles.player,
    },
  };
};

export const getActionPot = (
  gameConfig: GameConfig | undefined,
  action: SeqActionAny,
  isRoundStarting = false
) => {
  if (isActionWithData(action)) {
    return getActionPotByStateInfo(
      gameConfig,
      action.stateInfo,
      isRoundStarting
    );
  }
  return undefined;
};

export const getActionPotByStateInfo = (
  gameConfig: GameConfig | undefined,
  stateInfo: StateInfo,
  isRoundStarting = false
) => {
  if (!gameConfig) {
    return undefined;
  }
  const unit = gameConfig.normalizingUnit;
  const { pot, roundContrib } = stateInfo;
  if (!isRoundStarting) {
    return stateInfo.pot / unit;
  }
  const contribSum = roundContrib.reduce((acc, val) => acc + val);
  return (pot - contribSum) / unit;
};
