import { SeqWidgetActionTypeEnum } from "@/enums/seqWidgetEnum";
import {
  isSeqWidgetCompoundActionWithOrder,
  isSeqWidgetStreetActionGroup,
  isSeqWidgetStreetActionWithOrder,
} from "@/features/sequence/widget/guards";
import {
  isActionWithData,
  isRoundEndingAction,
  isSkippedAction,
  isTerminatedAction,
  isUiHiddenAction,
} from "@/features/sequence/action";

import type { SeqActionAny } from "@/types/seqAction";
import type {
  SeqWidgetAnyActionWithOrder,
  SeqWidgetCompoundActionSingleOrStreetActionGroup,
  SeqWidgetStreetActionGroup,
  SeqWidgetStreetActionWithOrder,
} from "@/types/seqWidget";

export const getLastActionWithDataInGroup = (seqArr: SeqActionAny[]) => {
  const arr = getSeqWithGroups(seqArr);
  const lastGroup = arr[arr.length - 1] as SeqWidgetStreetActionGroup;
  for (let i = lastGroup.data.length - 1; i >= 0; i--) {
    if (isActionWithData(lastGroup.data[i].seqAction)) {
      return lastGroup.data[i];
    }
  }
  return null;
};

export const getFilterSeqByGroups = (
  sequence: SeqWidgetCompoundActionSingleOrStreetActionGroup[],
  isCollapsed: boolean
) => {
  if (isCollapsed) {
    return sequence.map((item) => {
      if (isSeqWidgetStreetActionGroup(item)) {
        const filteredData = item.data.filter(
          (action) => !isSkippedAction(action.seqAction)
        );
        return {
          ...item,
          data: filteredData,
        };
      }
      return item;
    });
  }
  return sequence;
};

export const getSeqWithGroups = (
  sequence: SeqActionAny[]
): SeqWidgetCompoundActionSingleOrStreetActionGroup[] => {
  let streetActionArr: SeqWidgetStreetActionWithOrder[] = [];
  const result: SeqWidgetCompoundActionSingleOrStreetActionGroup[] = [];
  let isPrevActionRoundEnding = false;

  const pushGroup = () => {
    result.push({
      type: SeqWidgetActionTypeEnum.group,
      data: streetActionArr,
    });
  };
  const actionWithOrderArr: SeqWidgetAnyActionWithOrder[] = sequence.map(
    (item, idx) => ({
      order: idx,
      seqAction: item,
    })
  );
  actionWithOrderArr.forEach((item) => {
    if (isSeqWidgetStreetActionWithOrder(item)) {
      if (isRoundEndingAction(item.seqAction)) {
        streetActionArr.push(item);
        isPrevActionRoundEnding = true;
      } else if (isTerminatedAction(item.seqAction)) {
        streetActionArr.push(item);
        pushGroup();
        streetActionArr = [];
      } else if (isSkippedAction(item.seqAction)) {
        streetActionArr.push(item);
      } else if (isPrevActionRoundEnding) {
        isPrevActionRoundEnding = false;
        pushGroup();
        streetActionArr = [];
        streetActionArr.push(item);
      } else {
        streetActionArr.push(item);
      }
    } else {
      if (isSeqWidgetCompoundActionWithOrder(item)) {
        if (isPrevActionRoundEnding) {
          isPrevActionRoundEnding = false;
          pushGroup();
          streetActionArr = [];
        }
        result.push({
          data: item,
          type: SeqWidgetActionTypeEnum.single,
        });
      }
    }
  });
  if (streetActionArr.length > 0) {
    pushGroup();
  }
  return result;
};

export const filteredSeqWithoutUiHidden = (sequence: SeqActionAny[]) => {
  return sequence.filter((action) => !isUiHiddenAction(action));
};

export const getActiveGroup = (seq: SeqActionAny[], seqPointer: number) => {
  const arr = getSeqWithGroups(seq);

  return arr.find(
    (item) =>
      isSeqWidgetStreetActionGroup(item) &&
      item.data.some((action) => action.order === seqPointer)
  ) as SeqWidgetStreetActionGroup;
};
