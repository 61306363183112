import { defineStore } from "pinia";
import { computed, ref, shallowRef } from "vue";
import pokerApi from "@/services/pokerApi";
import { useParsedLibDataStore } from "@/stores/parsedLibData";
import { useGameStore } from "@/stores/game";
import { getMatrix } from "@/composables/useMatrixData";
import { PokerActionsEnum } from "@/poker-query-lib/enums/poker-actions.enum";
import { SeqActionStateEnum, SeqActionUiStateEnum } from "@/enums/sequenceEnum";
import {
  getBoardCardsAction,
  getEmptyAction,
  getEmptyActions,
  getOpenAction,
  getSkippedAction,
  getTerminatedAction,
  getToActAction,
  getUpCardAction,
  isActionWithData,
  isBoardCardsAction,
  isDiscardAction,
  isEmptyAction,
  isPreflopAction,
  isRoundEndingAction,
  isSingleUpCardAction,
  isSkippedAction,
  isTerminatedAction,
  isToActAction,
  isUiEmptyAction,
} from "@/features/sequence/action";
import {
  findAndSetConnectionName,
  isDrawGameCategory,
  isFlopGameCategory,
  isStudGameCategory,
} from "@/features/gameSettings";
import { getSeqFromSeqStateReq } from "@/features/queryHandlers";
import {
  checkPreviousFoldOfPlayer,
  getActionIdxOnTablePlayerClick,
} from "@/features/sequence/utils";
import { useBoardCards } from "@/composables/useBoardCards";
import { getPlayersWithAdditionalInfo } from "@/features/playersWithAdditionalInfo";
import { isPlayerActive, isPlayerFolded } from "@/features/pokerPlayers";
import {
  isSeqWidgetCompoundActionSingle,
  isSeqWidgetStreetActionGroup,
} from "@/features/sequence/widget/guards";
import { useUpCards } from "@/composables/useUpCards";
import { PlayerList } from "@/entities/Player";
import { isActionListingResponse } from "@/utils/utilityFunctions";
import {
  isCheckActionName,
  isFoldActionName,
  isGameEndingActionName,
  isRoundEndingActionName,
} from "@/features/action/actionGeneral";
import {
  getActiveGroup,
  getSeqWithGroups,
} from "@/features/sequence/widget/groups";
import { getSingleCardPerPlayer } from "@/features/cards/upCards";

import type {
  PerformActionParams,
  PreflopSequenceMapRecord,
  SequenceStoreInitParams,
} from "@/types/sequence";
import type {
  SeqAction,
  SeqActionAny,
  SeqActionBoardCards,
} from "@/types/seqAction";
import type {
  ActionListingData,
  ActionListingReqParams,
} from "@/types/actionListingJson";
import type {
  SeqWidgetCardWidgetMouseLeave,
  SeqWidgetCompoundActionSingleOrStreetActionGroup,
  SeqWidgetStreetActionGroup,
} from "@/types/seqWidget";
import type { SelectedGameStrategy } from "@/types/gameSettings";
import type { UpCardsRecords } from "@/types/upCards";
import type { PlayerWithAdditionalInfo } from "@/types/player";

export const useStudySequenceStore = defineStore(
  "study-sequence",
  () => {
    const seqDictionary = shallowRef<Record<string, PreflopSequenceMapRecord>>(
      {}
    );
    const playersNum = ref(0);
    const playerList = ref<PlayerList>();
    const seqPointer = ref(-1);
    const sequence = ref<SeqActionAny[]>([]);
    const showStudyViewLoading = ref(false);
    const isFetchingActionListing = ref(false);
    const isFetchingSeqMap = ref(false);

    const gameStore = useGameStore();
    const { setMatrixData, clearMatrixData } = useParsedLibDataStore();
    const {
      boardCards,
      boardCardsActionIdx,
      cardsModalIsDisabledConfirm,
      closeBoardCardsModal,
      confirmBoardCardsModal,
      getBoardCards,
      getBoardCardsForRequest,
      getBoardCardsFromSeq,
      initBoardCards,
      isOpenBoardCardsModal,
      maxNumTableCards,
      modalBoardCards,
      nonUnselectableCards,
      openBoardCardsModal,
      removeCardInBoardCardsModal,
      resetBoardCardsModal,
      selectCardInBoardCardsModal,
      setBoardCards,
      setCardsActionIdx,
      updateBoardCards,
    } = useBoardCards(seqPointer, sequence);
    const {
      addSingleUpCardToEachPlayer,
      closeUpCardsModal,
      confirmUpCardsModal,
      foldedPlayerNames,
      getHeroCardForRequest,
      getUpCardsForRequest,
      handleHeroCard,
      heroCard,
      initUpCards,
      isOpenUpCardsModal,
      openUpCardsModal,
      resetUpCards,
      setHeroCard,
      setUpCards,
      upCards,
      upCardsActionIdx,
      upCardsActiveAction,
    } = useUpCards(seqPointer, sequence);

    const getOpenData = () => {
      return Object.keys(seqDictionary.value).includes("open")
        ? seqDictionary.value.open
        : sequence.value.length
        ? {
            actionsList: (sequence.value.find(isActionWithData) as SeqAction)
              .actionsList,
            stateInfo: (sequence.value.find(isActionWithData) as SeqAction)
              .stateInfo,
          }
        : null;
    };
    const activePlayerUpCards = computed(() => {
      if (playersWithAdditionalInfo.value && upCards.value) {
        const activePlayer =
          playersWithAdditionalInfo.value.find(isPlayerActive);
        if (activePlayer) {
          return upCards.value[activePlayer.name];
        } else {
          return [];
        }
      } else {
        return [];
      }
    });
    const numCompletedActions = computed(() => {
      return sequence.value.filter((action) => !isEmptyAction(action)).length;
    });
    const isCallEnd = (actionName = activeAction.value.chosenAction) => {
      return [
        `${PokerActionsEnum.CALL}/`,
        `${PokerActionsEnum.CALL}/$`,
      ].includes(actionName);
    };
    const isGameEnd = computed(() => {
      return isTerminatedAction(activeAction.value);
    });
    const isRoundEnd = computed(() => {
      return isRoundEndingAction(activeAction.value);
    });
    const activeAction = computed(() => {
      return sequence.value[seqPointer.value] as SeqAction;
    });
    const seqForRequest = computed(() => {
      return activeAction.value ? activeAction.value.sequence : "open";
    });
    const isCurrentActionOnPreflop = computed(() => {
      return isPreflopAction(seqPointer.value, sequence.value);
    });
    const seqPlayerIdx = computed(() => {
      return seqPointer.value % playersNum.value;
    });
    const isAllowedNextAction = computed(() => {
      return seqPointer.value < numCompletedActions.value - 1;
    });
    const isAllowedPrevAction = computed(() => {
      if (isSingleUpCardAction(sequence.value[0]) && seqPointer.value === 1) {
        return false;
      } else {
        return seqPointer.value > 0;
      }
    });
    const playersWithAdditionalInfo = computed(() => {
      return getPlayersWithAdditionalInfo(
        playerList.value?.data,
        sequence.value,
        seqPointer.value
      );
    });
    const activePlayersNum = computed(() => {
      return playersWithAdditionalInfo.value
        ? playersWithAdditionalInfo.value.filter(
            (player) => !isPlayerFolded(player)
          ).length
        : 0;
    });
    const playersNumInRound = computed(() => {
      const arr = getSeqWithGroups(sequence.value);
      const getFirstActionInGroup = (idx: number) => {
        const activeItem = arr.find(
          (item) =>
            (isSeqWidgetStreetActionGroup(item) &&
              item.data.some((action) => action.order === idx)) ||
            (isSeqWidgetCompoundActionSingle(item) && item.data.order === idx)
        ) as SeqWidgetCompoundActionSingleOrStreetActionGroup;
        if (activeItem && isSeqWidgetStreetActionGroup(activeItem)) {
          return activeItem.data[0].seqAction as SeqAction;
        } else if (activeItem && isSeqWidgetCompoundActionSingle(activeItem)) {
          const activeItemIdx = arr.findIndex((item) => item === activeItem);
          for (let i = activeItemIdx; i < arr.length; i++) {
            const arrItem = arr[i];
            if (isSeqWidgetStreetActionGroup(arrItem)) {
              return arrItem.data[0].seqAction as SeqAction;
            }
          }
          return null;
        } else {
          return null;
        }
      };
      const firstActionInGroup = getFirstActionInGroup(seqPointer.value);
      return firstActionInGroup
        ? firstActionInGroup.stateInfo.folded.filter((item) => !item).length
        : playersNum.value;
    });

    const setSeqAction = (action: SeqActionAny, idx = seqPointer.value) => {
      sequence.value[idx] = action;
    };
    const addActionToSeq = (action: SeqActionAny) => {
      sequence.value.push(action);
    };
    const changeActionName = (newActionName: string) => {
      const currentAction = sequence.value[seqPointer.value];
      if (isActionWithData(currentAction)) {
        currentAction.chosenAction = newActionName;
      }
    };
    const changeActionState = (state: SeqActionStateEnum) => {
      sequence.value[seqPointer.value].state = state;
    };
    const addActionToSeqAndShiftPointer = (action: SeqActionAny) => {
      addActionToSeq(action);
      shiftSeqPointer();
    };
    const shiftPointerAndSetAction = (action: SeqActionAny) => {
      setSeqAction(action, seqPointer.value + 1);
      shiftSeqPointer();
    };
    const activeGroup = computed(() => {
      return getActiveGroup(sequence.value, seqPointer.value);
    });
    const isActionOnFirstLevel = computed(() => {
      if (activeGroup.value) {
        const firstActionInGroupOrder = activeGroup.value.data[0].order;
        return (
          seqPointer.value - firstActionInGroupOrder < playersNumInRound.value
        );
      } else {
        return false;
      }
    });
    const isFirstActionOnFirstLevel = computed(() => {
      if (activeGroup.value) {
        const firstActionInGroupOrder = activeGroup.value.data[0].order;
        return seqPointer.value === firstActionInGroupOrder;
      } else {
        return false;
      }
    });
    const isLastActionInFirstLevel = computed(() => {
      if (activeGroup.value) {
        const firstRow = activeGroup.value.data.slice(0, playersNum.value);
        const lastActionInFirstRowOrder = firstRow[firstRow.length - 1].order;

        return seqPointer.value === lastActionInFirstRowOrder;
      }
      return false;
    });
    const onAsideTablePlayerClick = (player: PlayerWithAdditionalInfo) => {
      const actionIdx = getActionIdxOnTablePlayerClick({
        activeAction: activeAction.value,
        player,
        sequence: sequence.value,
        seqPointer: seqPointer.value,
        playersWithAdditionalInfo: playersWithAdditionalInfo.value,
      });
      if (actionIdx) {
        performAction({
          idx: actionIdx,
          actionName: "",
        });
      }
    };
    const handlePerformAction = (
      seqStr: string,
      data: ActionListingData | PreflopSequenceMapRecord
    ) => {
      if (isActionListingResponse(data) && data.isDiscardAction) {
        addActionToSeqAndShiftPointer(getToActAction(seqStr, data));
      } else {
        if (isActionOnFirstLevel.value && !isLastActionInFirstLevel.value) {
          shiftPointerAndSetAction(getToActAction(seqStr, data));
          return;
        }
        if (!isDiscardAction(activeAction.value)) {
          handleSkipActions();
        }
        addActionToSeqAndShiftPointer(getToActAction(seqStr, data));
      }
    };
    const performRegularAction = async (seqStr: string) => {
      if (seqDictionary.value[seqStr]) {
        handlePerformAction(seqStr, seqDictionary.value[seqStr]);
        return Promise.resolve();
      } else {
        return getActionListing(seqStr, {
          upCards: getUpCardsForRequest(),
        }).then((data) => {
          handlePerformAction(seqStr, data);
        });
      }
    };
    const performRoundAction = async (
      seqStr: string,
      idx: number,
      actionName: string
    ) => {
      if (isDrawGameCategory(gameStore.configByConnectionName?.gameCategory)) {
        await performRegularAction(seqStr);
      } else if (
        isFlopGameCategory(gameStore.configByConnectionName?.gameCategory)
      ) {
        openBoardCardsModal(idx + 1, actionName);
        changeActionName(actionName);
      } else if (
        isStudGameCategory(gameStore.configByConnectionName?.gameCategory)
      ) {
        openUpCardsModal();
        changeActionName(actionName);
      }
    };
    const performEndAction = async (seqStr: string) => {
      if (isActionOnFirstLevel.value && !isLastActionInFirstLevel.value) {
        shiftPointerAndSetAction(
          getTerminatedAction(seqStr, activeAction.value.stateInfo)
        );
      } else {
        handleSkipActions();
        addActionToSeqAndShiftPointer(
          getTerminatedAction(seqStr, activeAction.value.stateInfo)
        );
      }
    };
    // Perform action handlers ↑ ↑ ↑
    const shiftSeqPointerNext = () => {
      const currentAction = () => sequence.value[seqPointer.value];
      if (isActionWithData(activeAction.value)) {
        changeActionState(SeqActionStateEnum.performed);
      }
      seqPointer.value++;
      currentAction().uiState = SeqActionUiStateEnum.visible;
      if (
        isSkippedAction(activeAction.value) ||
        isBoardCardsAction(activeAction.value) ||
        isSingleUpCardAction(activeAction.value)
      ) {
        if (
          isBoardCardsAction(activeAction.value) ||
          isSingleUpCardAction(activeAction.value)
        ) {
          sequence.value
            .slice(
              seqPointer.value + 1,
              seqPointer.value + 1 + playersNumInRound.value
            )
            .forEach(
              (action) => (action.uiState = SeqActionUiStateEnum.visible)
            );
        }
        shiftSeqPointer();
      }
      if (isActionWithData(activeAction.value)) {
        changeActionState(SeqActionStateEnum.toAct);
        if (isActionOnFirstLevel.value && isFirstActionOnFirstLevel.value) {
          sequence.value
            .slice(seqPointer.value, seqPointer.value + playersNumInRound.value)
            .forEach(
              (action) => (action.uiState = SeqActionUiStateEnum.visible)
            );
        }
      }
      if (isTerminatedAction(activeAction.value)) {
        changeActionState(SeqActionStateEnum.performed);
      }
    };
    const shiftSeqPointerPrev = () => {
      const currentAction = () => sequence.value[seqPointer.value];
      if (isActionOnFirstLevel.value && !isFirstActionOnFirstLevel.value) {
        if (
          isActionWithData(activeAction.value) ||
          isTerminatedAction(activeAction.value)
        ) {
          changeActionState(SeqActionStateEnum.uiEmpty);
        }
        seqPointer.value--;
        if (isSkippedAction(activeAction.value)) {
          shiftSeqPointer(false);
        }
        if (isActionWithData(activeAction.value)) {
          changeActionState(SeqActionStateEnum.toAct);
        }
      } else {
        currentAction().uiState = SeqActionUiStateEnum.hidden;
        seqPointer.value--;
        if (
          isSkippedAction(activeAction.value) ||
          isBoardCardsAction(activeAction.value) ||
          isSingleUpCardAction(activeAction.value)
        ) {
          if (
            isBoardCardsAction(activeAction.value) ||
            isSingleUpCardAction(activeAction.value)
          ) {
            for (let i = seqPointer.value; i < sequence.value.length; i++) {
              sequence.value[i].uiState = SeqActionUiStateEnum.hidden;
            }
          }
          shiftSeqPointer(false);
        }
        if (isActionWithData(activeAction.value)) {
          changeActionState(SeqActionStateEnum.toAct);
        }
        if (isDiscardAction(activeAction.value)) {
          for (let i = seqPointer.value + 1; i < sequence.value.length; i++) {
            sequence.value[i].uiState = SeqActionUiStateEnum.hidden;
          }
        }
      }
    };
    const shiftSeqPointer = (isNext = true) => {
      clearMatrixData();
      if (isNext) {
        shiftSeqPointerNext();
      } else {
        shiftSeqPointerPrev();
      }
    };
    const handleSkipActions = () => {
      let nextPlayerIdx = seqPointer.value + 1;
      while (
        checkPreviousFoldOfPlayer(
          nextPlayerIdx,
          playersNumInRound.value,
          activeGroup.value
        )
      ) {
        addActionToSeq(getSkippedAction());
        nextPlayerIdx++;
      }
    };
    const heroCardHandler = (card: string) => {
      handleHeroCard(card);
      handleConfirmUpCardsModal(upCards.value as UpCardsRecords, card);
    };
    const handleCloseBoardCardsModal = () => {
      closeBoardCardsModal();
      if (!isTerminatedAction(activeAction.value)) {
        changeActionState(SeqActionStateEnum.toAct);
      }
    };
    const handleCloseUpCardsModal = () => {
      closeUpCardsModal();
      upCardsActionIdx.value = -1;
      if (!isTerminatedAction(activeAction.value)) {
        changeActionState(SeqActionStateEnum.toAct);
      }
    };
    const handleConfirmBoardCardsModal = () => {
      clearMatrixData();
      confirmBoardCardsModal();
      while (boardCardsActionIdx.value < seqPointer.value) {
        shiftSeqPointer(false);
      }
      sequence.value.splice(boardCardsActionIdx.value);
      addActionToSeq(getBoardCardsAction(boardCards.value));
      const activeActionSequence =
        activeAction.value.sequence + activeAction.value.chosenAction;
      getActionListing(activeActionSequence, {
        boardCards: boardCards.value.join(""),
      }).then((data) => {
        addActionToSeqAndShiftPointer(
          getToActAction(activeActionSequence, data)
        );
        sequence.value.push(...getEmptyActions(activePlayersNum.value - 1));
      });
    };
    const handleConfirmUpCardsModal = (
      upCardsRecords: UpCardsRecords,
      heroCardParam = ""
    ) => {
      confirmUpCardsModal(upCardsRecords);
      if (upCardsActionIdx.value === seqPointer.value) {
        addActionToSeq(getUpCardAction(upCardsRecords, heroCardParam));
        const activeActionSequence =
          activeAction.value.sequence + activeAction.value.chosenAction;
        getActionListing(activeActionSequence, {
          upCards: getUpCardsForRequest(true),
        }).then((data) => {
          addActionToSeqAndShiftPointer(
            getToActAction(activeActionSequence, data)
          );
          sequence.value.push(...getEmptyActions(activePlayersNum.value - 1));
        });
      } else {
        while (upCardsActionIdx.value + 1 < seqPointer.value) {
          shiftSeqPointer(false);
        }
        sequence.value.splice(upCardsActionIdx.value + 2);
        setSeqAction(
          getUpCardAction(upCardsRecords, heroCardParam),
          upCardsActionIdx.value
        );
        upCardsActionIdx.value = -1;
        const activeActionSequence = activeAction.value.sequence;
        getActionListing(activeActionSequence, {
          upCards: getUpCardsForRequest(),
        }).then((data) => {
          setSeqAction(getToActAction(activeActionSequence, data));
          sequence.value.push(...getEmptyActions(activePlayersNum.value - 1));
        });
      }
    };
    const handleCardWidgetSelect = (data: SeqWidgetCardWidgetMouseLeave) => {
      const boardCardsAction = sequence.value[
        data.order
      ] as SeqActionBoardCards;
      boardCardsAction.boardCards[boardCardsAction.boardCards.length - 1] =
        data.card;
      setBoardCards(boardCardsAction.boardCards);
      setCardsActionIdx(data.order);
      handleConfirmBoardCardsModal();
    };
    const performActionByActionName = async ({
      actionName,
      resetCurrentActionStateToToAct = false,
      idx = seqPointer.value,
    }: PerformActionParams) => {
      clearMatrixData();
      if (isFetchingActionListing.value) {
        return;
      }
      if (resetCurrentActionStateToToAct || idx !== seqPointer.value) {
        // Action when breadcrumb clicked and Action from tooltip
        while (idx < seqPointer.value) {
          shiftSeqPointer(false);
        }
        updateBoardCards(idx, resetCurrentActionStateToToAct);
        cutSeqAndSetEmptyActions();
      }

      const seqStr =
        seqForRequest.value === "open"
          ? actionName
          : seqForRequest.value + actionName;
      if (!resetCurrentActionStateToToAct) {
        changeActionName(actionName);
        changeActionState(SeqActionStateEnum.performed);
        cutSeqAndSetEmptyActions();
        if (isRoundEndingActionName(actionName)) {
          if (gameStore.isPreflopOnlyStrategy) {
            await performEndAction(seqStr);
          } else {
            await performRoundAction(seqStr, idx, actionName);
          }
        } else if (isGameEndingActionName(actionName)) {
          await performEndAction(seqStr);
        } else {
          await performRegularAction(seqStr);
          cutSeqAndSetEmptyActions();
        }
      }
    };
    const performAction = async ({
      actionName,
      idx = seqPointer.value,
      resetCurrentActionStateToToAct = false,
    }: PerformActionParams) => {
      const action = sequence.value[idx];
      if (isActionWithData(action) && !isUiEmptyAction(action)) {
        await performActionByActionName({
          actionName,
          idx,
          resetCurrentActionStateToToAct,
        });
      } else if (isEmptyAction(action) || isUiEmptyAction(action)) {
        for (let i = seqPointer.value; i < idx; i++) {
          await performActionByActionName({
            actionName:
              activeAction.value.actionsList.find(
                (actionNameFromList) =>
                  isFoldActionName(actionNameFromList) ||
                  isCheckActionName(actionNameFromList)
              ) || "",
          });
        }
      }
    };
    const setInitSeqState = (params?: SequenceStoreInitParams) => {
      clearMatrixData();
      if (gameStore.configByConnectionName) {
        const gameConfig = gameStore.configByConnectionName;
        const { numPlayers } = gameConfig;
        playersNum.value = numPlayers;
        playerList.value = new PlayerList(gameConfig);
        const openData = getOpenData();
        sequence.value = [];
        seqPointer.value = 0;
        if (gameStore.isCurrentGameStud && upCards.value && !params?.upCards) {
          resetUpCards();
          sequence.value.push(getUpCardAction(upCards.value));
          seqPointer.value = 1;
        } else {
          initUpCards();
        }
        if (!params?.seq && openData) {
          if (gameStore.isCurrentGameStud && params?.upCards) {
            const initialUpCardsFromString = getSingleCardPerPlayer(
              params.upCards,
              gameConfig.playerNames
            );
            initUpCards(initialUpCardsFromString, {
              playerNames: gameConfig.playerNames,
            });
            sequence.value.push(
              getUpCardAction(upCards.value as UpCardsRecords)
            );
            seqPointer.value = 1;
          }
          setSeqAction(getOpenAction(openData));
          sequence.value.push(...getEmptyActions(numPlayers - 1));
          initBoardCards();
        } else if (params?.seq) {
          if (gameStore.isCurrentGameStud && params?.upCards) {
            initUpCards(params.upCards, {
              playerNames: gameConfig.playerNames,
            });
          }
          if (gameStore.isCurrentGameStud && params?.heroCard) {
            setHeroCard(params.heroCard);
          }
          sequence.value = [...params.seq];
          seqPointer.value = params.seq.findIndex(
            (action) => isToActAction(action) || isTerminatedAction(action)
          );
          const cards = getBoardCards();
          initBoardCards(cards);
        }
      }
    };
    const initStudySeqStore = async (params?: SequenceStoreInitParams) => {
      if (!params?.seq) {
        await Promise.all([
          getActionListing("open", {
            upCards: params?.upCards
              ? getSingleCardPerPlayer(
                  params.upCards,
                  gameStore.configByConnectionName?.playerNames as string[]
                )
              : undefined,
          }).then(() => {
            setInitSeqState(params);
          }),
          fetchSeqDict(),
        ]);
      } else if (params.seq && params?.seqStr) {
        getActionListing(params.seqStr, {
          boardCards: getBoardCardsFromSeq(params.seq)?.join(""),
          upCards: params?.upCards,
        }).then(() => {
          fetchSeqDict();
          setInitSeqState(params);
        });
      }
    };
    const getActionListing = (
      seqStr: string,
      reqParams?: ActionListingReqParams
    ) => {
      const params = {
        boardCards: reqParams?.boardCards
          ? reqParams.boardCards
          : getBoardCardsForRequest(),
        upCards: reqParams?.upCards ? reqParams?.upCards : undefined,
      };
      isFetchingActionListing.value = true;
      return pokerApi
        .fetchActionListingJson(seqStr, params)
        .then((res) => {
          if (seqStr === "open") {
            seqDictionary.value[seqStr] = {
              actionsList: res.data.data.actionsList,
              stateInfo: res.data.data.stateInfo,
            };
          }
          return res.data.data;
        })
        .finally(() => {
          isFetchingActionListing.value = false;
        });
    };
    const cutSeqAndSetEmptyActions = () => {
      sequence.value.splice(seqPointer.value + 1);
      if (isActionOnFirstLevel.value) {
        const seqArr = getSeqWithGroups(sequence.value);
        const activeGroup = seqArr.find(
          (item) =>
            isSeqWidgetStreetActionGroup(item) &&
            item.data.some((action) => action.order === seqPointer.value)
        ) as SeqWidgetStreetActionGroup;
        const firstLevelInGroup = activeGroup.data.slice(
          0,
          playersNumInRound.value
        );
        const indexOfActiveItem = firstLevelInGroup.findIndex(
          (item) => item.order === seqPointer.value
        );
        const amountOfEmpties =
          playersNumInRound.value - (indexOfActiveItem + 1);
        for (let i = 0; i < amountOfEmpties; i++) {
          addActionToSeq(getEmptyAction());
        }
      }
    };
    const fetchSeqDict = async () => {
      if (gameStore.isCurrentGameStud) {
        return;
      }
      isFetchingSeqMap.value = true;
      pokerApi
        .fetchPreflopSequenceJsonMap()
        .then((res) => {
          seqDictionary.value = res.data.data;
        })
        .finally(() => {
          isFetchingSeqMap.value = true;
        });
    };
    const fetchAndSetMatrix = () => {
      if (gameStore.connectionName) {
        showStudyViewLoading.value = true;
        getMatrix(activeAction.value.sequence, {
          boardCards: getBoardCardsForRequest(),
          upCards: getUpCardsForRequest(),
          heroCard: getHeroCardForRequest(),
        })
          .then(() => {
            setMatrixData(true);
          })
          .finally(() => {
            showStudyViewLoading.value = false;
          });
      }
    };
    const settingsChangeHandler = async (strategy: SelectedGameStrategy) => {
      initBoardCards();
      await findAndSetConnectionName(false);
      await initStudySeqStore({
        upCards: "upCards" in strategy ? strategy.upCards : undefined,
      });
    };
    const setSeqStateFromQuery = (
      seq: string,
      options?: {
        boardCards?: string[];
        upCards?: string;
        heroCard?: string;
      }
    ) => {
      if (seq !== "open") {
        pokerApi
          .fetchSeqStateHistory(seq, { upCards: options?.upCards })
          .then(async (res) => {
            const sequenceFromReq = getSeqFromSeqStateReq(res.data.data, {
              boardCards: options?.boardCards,
              withSkippedAndEmptyActions: true,
              upCardsOptions: {
                upCards: options?.upCards,
                heroCard: options?.heroCard,
                playerNames: gameStore.configByConnectionName?.playerNames,
              },
            });
            const lastActionWithDataInSeq = sequenceFromReq.find((action) =>
              isToActAction(action)
            ) as SeqAction;
            await initStudySeqStore({
              seq: sequenceFromReq,
              seqStr: lastActionWithDataInSeq.sequence,
              upCards: options?.upCards,
              heroCard: options?.heroCard,
            });
          })
          .catch(async () => {
            await initStudySeqStore(options);
            gameStore.updatePageQueries(seqForRequest.value);
          });
      } else {
        initStudySeqStore();
      }
    };

    return {
      activeAction,
      activePlayerUpCards,
      fetchAndSetMatrix,
      heroCardHandler,
      initStudySeqStore,
      isActionOnFirstLevel,
      isAllowedNextAction,
      isAllowedPrevAction,
      isCallEnd,
      isCurrentActionOnPreflop,
      isFetchingActionListing,
      isGameEnd,
      isRoundEnd,
      onAsideTablePlayerClick,
      performAction,
      playerList,
      playersNumInRound,
      playersWithAdditionalInfo,
      seqDictionary,
      seqForRequest,
      seqPlayerIdx,
      seqPointer,
      sequence,
      setInitSeqState,
      setSeqStateFromQuery,
      settingsChangeHandler,
      shiftSeqPointer,
      showStudyViewLoading,
      boardCardsModule: ref({
        cards: boardCards,
        maxNumTableCards,
        nonUnselectableCards,
        changeCardWithCardWidget: handleCardWidgetSelect,
        getCardsForRequest: getBoardCardsForRequest,
        cardsModal: {
          cards: modalBoardCards,
          isOpen: isOpenBoardCardsModal,
          isDisabledConfirm: cardsModalIsDisabledConfirm,
          open: openBoardCardsModal,
          close: handleCloseBoardCardsModal,
          confirm: handleConfirmBoardCardsModal,
          reset: resetBoardCardsModal,
          removeCard: removeCardInBoardCardsModal,
          selectCard: selectCardInBoardCardsModal,
        },
      }),
      upCardsModule: ref({
        activeAction: upCardsActiveAction,
        addSingleCardToEachPlayer: addSingleUpCardToEachPlayer,
        cards: upCards,
        foldedPlayerNames,
        getCardsForRequest: getUpCardsForRequest,
        getHeroCardForRequest,
        heroCard,
        heroCardHandler,
        setCards: setUpCards,
        upCardsActionIdx,
        cardsModal: {
          close: handleCloseUpCardsModal,
          confirm: handleConfirmUpCardsModal,
          isOpen: isOpenUpCardsModal,
          open: openUpCardsModal,
        },
      }),
    };
  },
  {
    share: {
      enable: false,
    },
  }
);
