// for title using key values for translate
export const gameNavigation = [
  {
    title: "preflop",
    link: "/preflop",
    slug: "preflop",
  },
  {
    title: "study",
    link: "/study",
    slug: "study",
  },
];

export const infoNavigation = [
  {
    title: "Product",
    link: "/product",
    slug: "product",
  },
  {
    title: "Pricing",
    link: "/pricing",
    slug: "pricing",
  },
  {
    title: "FAQ",
    link: "/faq",
    slug: "faq",
  },
];
