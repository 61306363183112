import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "empty-layout-without-header"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.isWithoutLayout)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_RouterView, null, {
            default: _withCtx(({ Component }) => [
              _createVNode(_Transition, {
                name: "fade",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          })
        ]))
      : ($setup.isProtectedRoute)
        ? (_openBlock(), _createBlock($setup["MainLayout"], { key: 1 }))
        : (!$setup.isProtectedRoute && $setup.isSubscriptionPages)
          ? (_openBlock(), _createBlock($setup["SubscriptionLayout"], { key: 2 }))
          : (_openBlock(), _createBlock($setup["EmptyLayout"], { key: 3 })),
    (!$setup.gameStore.isApiAvailable)
      ? (_openBlock(), _createBlock($setup["UnavailableOverlay"], { key: 4 }))
      : _createCommentVNode("", true),
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        ($setup.layoutStore.isVisibleFocusModeToast)
          ? (_openBlock(), _createBlock($setup["ToastFocusMode"], { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}