import { positionMap } from "@/features/pokerTablePositions";
import { createFilledArray } from "@/utils/array";
import { isStudGameCategory } from "@/features/gameSettings";
import { CardSuitEnum } from "@/enums/cardEnum";
import { PlayerCardTypeEnum } from "@/enums/playerEnum";
import type { GameConfig } from "@/types/gameSettings";
import type { PokerTablePositionEnum } from "@/enums/pokerTableEnum";
import type {
  Player as PlayerI,
  PlayerCard,
  PlayerStackWithContrib,
} from "@/types/player";
import type { PlayerStateEnum } from "@/enums/playerEnum";

interface PlayerListAdditionalParams {
  positionList?: PokerTablePositionEnum[];
}

export class Player {
  public cardList: PlayerCard[];
  readonly isDealer: boolean;
  readonly name: string;
  readonly playerIndex: number;
  readonly position: PokerTablePositionEnum;

  constructor({ cardList, isDealer, name, playerIndex, position }: PlayerI) {
    this.cardList = cardList;
    this.isDealer = isDealer;
    this.name = name;
    this.playerIndex = playerIndex;
    this.position = position;
  }
}

export class PlayerWithAdditionalInfo extends Player {
  readonly stackWithContrib: PlayerStackWithContrib;
  readonly state: PlayerStateEnum;
  readonly isInteractive: boolean;

  constructor(
    playerData: PlayerI,
    stackWithContrib: PlayerStackWithContrib,
    state: PlayerStateEnum,
    isInteractive: boolean
  ) {
    super(playerData);
    this.stackWithContrib = stackWithContrib;
    this.state = state;
    this.isInteractive = isInteractive;
  }
}

export class PlayerList {
  readonly data: Player[];

  constructor(gameConfig: GameConfig, params?: PlayerListAdditionalParams) {
    const posList = params?.positionList || positionMap[gameConfig.numPlayers];
    this.data = this.create(gameConfig, posList);
  }

  private createCards(num: number): PlayerCard[] {
    return createFilledArray(num, {
      isBack: true,
      type: PlayerCardTypeEnum.regular,
      value: CardSuitEnum.empty,
    });
  }

  private create(
    gameConfig: GameConfig,
    posList: PokerTablePositionEnum[]
  ): Player[] {
    const { firstPlayer, numPlayers, playerNames, numCards } = gameConfig;
    const dealerIdx = isStudGameCategory(gameConfig.gameCategory)
      ? null
      : (firstPlayer[1] + numPlayers - 1) % numPlayers;

    return playerNames.map((name, playerIndex) => {
      const cardList = this.createCards(numCards);
      const isDealer = playerIndex === dealerIdx;
      const position = posList[playerIndex];

      return new Player({ cardList, isDealer, name, playerIndex, position });
    });
  }
}
