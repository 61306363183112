import type { ActionListingData } from "@/types/actionListingJson";
import type { PreflopSequenceMapRecord } from "@/types/sequence";

export const assignValues = <T>(target: T, source: T) => {
  const copyValue = (targetObj: T, key: keyof T, value: any) => {
    if (!targetObj[key]) {
      return;
    }
    assignValues(targetObj[key], value);
  };

  if (target && (typeof target !== "object" || typeof source !== "object")) {
    throw new Error("Both arguments should be objects");
  }

  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      if (typeof source[key] === "object" && source[key] !== null) {
        copyValue(target, key, source[key]);
      } else {
        target[key] = source[key];
      }
    }
  }
};

export const deepEqual = <T>(obj1: T, obj2: T) => {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (
      !keys2.includes(key) ||
      !deepEqual(obj1[key as keyof T], obj2[key as keyof T])
    ) {
      return false;
    }
  }

  return true;
};

export const getNormalizedValue = (value: number) => {
  return Math.round(value * 100) / 100;
};

export const convertStackToBb = (value: string, normalizingUnit = 100) => {
  return `${+value / normalizingUnit}bb`;
};

export const convertUserForStudy = (value: string) => {
  return ["2", "4", "6"].includes(value) ? value : "Unknown value";
};

export const roundedNumber = (num: unknown) => {
  const roundedNum = Math.round(Number(num) * 10) / 10;
  let str = roundedNum.toString();
  if (str.includes(".") && str.endsWith(".0")) {
    str = str.slice(0, -2);
  }

  return str;
};

export const getActionPercentage = (percent: number) => {
  return Math.round(percent * 100);
};

export const getRoundOrLessValue = (value: number, isTableAction = false) => {
  if (value >= 1) {
    return Math.round(value);
  } else if (isTableAction && value < 1 && value >= 0.5) {
    return 1;
  } else if (value !== 0 && value < 1 && !isTableAction) {
    return "<1";
  } else {
    return 0;
  }
};

export const isActionListingResponse = (
  data: ActionListingData | PreflopSequenceMapRecord
): data is ActionListingData => {
  return "isDiscardAction" in data;
};

export const objectToURLSearchParams = (
  obj: Record<string, any>,
  parentKey = ""
) => {
  const params = new URLSearchParams();

  const addParams = (data: Record<string, any>, prefix: string) => {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key];
        const paramKey = prefix ? `${prefix}[${key}]` : key;

        if (typeof value === "object" && value !== null) {
          addParams(value, paramKey);
        } else {
          params.append(paramKey, String(value));
        }
      }
    }
  };

  addParams(obj, parentKey);

  return params;
};
