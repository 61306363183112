type NextItemInCircularArrayResult<T> = { item: T; index: number } | null;

export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  return Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, i) => {
    return array.slice(i * chunkSize, i * chunkSize + chunkSize);
  });
};

export const createFilledArray = <T>(numOfItems: number, content: T): T[] => {
  return Array.from({ length: numOfItems }, () => content);
};

export const isLastInArray = <T>(el: T, arr: T[]): boolean => {
  return arr.indexOf(el) === arr.length - 1;
};

export const findNextItemInCircularArray = <T>(
  array: T[],
  startIndex: number,
  condition: (item: T) => boolean
): NextItemInCircularArrayResult<T> => {
  if (!array.length) {
    throw new Error("Array is empty.");
  }
  if (startIndex < 0 || startIndex >= array.length) {
    throw new Error("Start index is out of bounds.");
  }

  let currentIndex = startIndex;
  do {
    currentIndex = (currentIndex + 1) % array.length;
    if (condition(array[currentIndex])) {
      return { item: array[currentIndex], index: currentIndex };
    }
  } while (currentIndex !== startIndex);

  return null;
};
