import { defineComponent as _defineComponent } from 'vue'
import HeaderSettings from "@/components/HeaderSettings.vue";
import HeaderSubscriptionSettings from "@/components/HeaderSubscriptionSettings.vue";
import NavHeader from "@/components/NavHeader.vue";
import type { NavigationItem } from "@/types/navigation";

interface Props {
  isPreflop?: boolean;
  isEmptyLayout?: boolean;
  isSubscriptionLayout?: boolean;
  navigation?: NavigationItem[];
}

export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderComponent',
  props: {
    isPreflop: { type: Boolean },
    isEmptyLayout: { type: Boolean },
    isSubscriptionLayout: { type: Boolean },
    navigation: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const __returned__ = { props, HeaderSettings, HeaderSubscriptionSettings, NavHeader }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})