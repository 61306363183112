import { defineComponent as _defineComponent } from 'vue'
import HeaderComponent from "@/components/layout/HeaderComponent.vue";
import { infoNavigation } from "@/constants/navigation";

export default /*@__PURE__*/_defineComponent({
  __name: 'SubscriptionLayout',
  setup(__props, { expose: __expose }) {
  __expose();


const __returned__ = { HeaderComponent, get infoNavigation() { return infoNavigation } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})