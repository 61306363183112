enum GameMode {
  spot = "spot",
  street = "street",
  fullHand = "fullHand",
}

enum BoardMode {
  current = "current",
  lastRandom = "lastRandom",
  fullyRandom = "fullyRandom",
}

enum UpCardsMode {
  current = "current",
  lastRandom = "lastRandom",
  fullyRandom = "fullyRandom",
}

enum ActionGradeEnum {
  best = "best",
  correct = "correct",
  inaccuracy = "inaccuracy",
  wrong = "wrong",
  blunder = "blunder",
  unknown = "unknown",
}

export { ActionGradeEnum, GameMode, BoardMode, UpCardsMode };
