import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import axios from "axios";
import fetchIntercept from "fetch-intercept";
import { useUserStore } from "@/stores/user";
import { useGameStore } from "@/stores/game";
import router from "@/router";
import routesName from "@/constants/routesName";
import AppToaster from "@/components/common/app/AppToaster.vue";
import { toast } from "vue3-toastify";
import type { ToastOptions } from "vue3-toastify";
import pokerApi from "@/services/pokerApi";
import { CACHE_NAME, cleanUpOldCache } from "@/utils/cacheApi";

declare module "axios" {
  export interface AxiosRequestConfig {
    useCache?: boolean;
  }

  export interface AxiosError<T = any, D = any> {
    isCache?: boolean;
  }
}

const urlWithCache =
  process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_API_URL_WITH_CACHE || ""
    : process.env.VUE_APP_API_URL_NO_CACHE || "";
const urlWithoutCache = process.env.VUE_APP_API_URL_NO_CACHE || "";

const axiosWithCache = axios.create({
  baseURL: urlWithCache,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const axiosNoCache = axios.create({
  baseURL: urlWithoutCache,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const requestInterceptor = (config: any) => {
  const userStore = useUserStore();
  if (!config) {
    config = {};
  }
  if (!config.headers) {
    config.headers = {};
  }

  if (userStore.accessToken && config.headers) {
    config.headers.Authorization = `Bearer ${userStore.accessToken}`;
  }

  if (config.useCache) {
    // cant use async/await on origin fetch interceptors
    const queryString = config.params
      ? `?${new URLSearchParams(config.params).toString()}`
      : "";
    const cacheUrl = `${config.baseURL || ""}${config.url}${queryString}`;
    return caches
      .open(CACHE_NAME)
      .then((cache) => cache.match(cacheUrl))
      .then((cachedResponse) => {
        if (cachedResponse) {
          return cachedResponse.json().then((cachedData) => {
            return Promise.reject({
              response: { data: cachedData },
              isCache: true,
            });
          });
        } else {
          return config;
        }
      });
  } else {
    return config;
  }
};

const errorInterceptor = async (
  error: AxiosError,
  axiosInstance: AxiosInstance
) => {
  const gameStore = useGameStore();
  const originalRequest = error.config;

  if (error.isCache) {
    return Promise.resolve(error.response);
  }

  if (error.response?.status === 401) {
    return refreshTokenHandle(originalRequest, axiosInstance, false);
  }

  if (error.response?.status === 503) {
    gameStore.setApiAvailability(false);
    return makeRequestWithRetry(originalRequest);
  }

  toast(AppToaster, {
    type: toast.TYPE.ERROR,
    data: {
      title: "Error",
      description: error.response?.data?.error?.message || "Unexpected error",
    },
  } as ToastOptions);

  if (!error.response?.config?.url?.includes("activity")) {
    await pokerApi.sendActivityConfig(error.response);
  }

  return Promise.reject(error);
};

const attachInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => requestInterceptor(config),
    (error: AxiosError) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => responseInterceptor(response),
    (error: AxiosError) => errorInterceptor(error, axiosInstance)
  );
};

const responseInterceptor = async (response: AxiosResponse) => {
  if (
    response.config?.url?.includes("poker-query") &&
    !response.config?.url?.includes("configs")
  ) {
    pokerApi.sendActivityConfig(response);
  }

  if (response.config.useCache) {
    const cache = await caches.open(CACHE_NAME);
    const queryString = response.config.params
      ? `?${new URLSearchParams(response.config.params).toString()}`
      : "";
    const cacheUrl = `${response.config.baseURL || ""}${
      response.config.url
    }${queryString}`;
    const headers = new Headers(response.headers);
    headers.set("X-Cached-Timestamp", String(Date.now()));

    const cachedResponse = new Response(JSON.stringify(response.data), {
      headers,
    });
    await cache.put(cacheUrl, cachedResponse);
  }

  return response;
};

cleanUpOldCache();

attachInterceptors(axiosWithCache);
attachInterceptors(axiosNoCache);
interface Subscriber {
  (token: string): void;
}
let subscribers: Subscriber[] = [];
let isRefreshing = false;
let fetchInterceptCounter = 0;

function onRefreshed(accessToken: string): void {
  subscribers.forEach((cb) => cb(accessToken));
}

function subscribeTokenRefresh(cb: Subscriber): void {
  subscribers.push(cb);
}

const makeRequestWithRetry = async (
  request: any,
  isFetch = false,
  retries = 3
) => {
  fetchInterceptCounter++;
  const gameStore = useGameStore();

  const fetchRequest = async (req: any) => {
    const response = await fetch(req.url, {
      ...req,
    });

    if (!response.ok) {
      throw new Error(`Fetch error: ${response.statusText}`);
    }

    return response;
  };
  const axiosRequest = async (req: any) => {
    return axios(req);
  };

  const waitTimes = [1000, 2000, 5000];
  let attempt = 0;

  while (attempt < retries) {
    try {
      await new Promise((resolve) =>
        setTimeout(resolve, waitTimes[attempt] || 5000)
      );

      const response = isFetch
        ? await fetchRequest(request)
        : await axiosRequest(request);

      gameStore.setApiAvailability(true);
      return response;
    } catch (error) {
      attempt++;
      if (attempt >= retries) {
        gameStore.setApiAvailability(false);
        gameStore.setIsServerResponding(false);
        toast(AppToaster, {
          type: toast.TYPE.ERROR,
          data: {
            title: "Error",
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            description: error.response?.data?.error?.message
              ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                error.response?.data?.error?.message
              : "Poker API is not available",
          },
        } as ToastOptions);
      }
    }
  }
  return false;
};

const fetchRequestOrigin = (originalRequest: Request) => {
  return new Promise((resolve) => {
    subscribeTokenRefresh((token) => {
      const newHeaders = new Headers(originalRequest.headers);
      newHeaders.set("Authorization", `Bearer ${token}`);
      resolve(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fetch(originalRequest.url, {
          ...originalRequest,
          headers: newHeaders,
        })
      );
    });
  });
};

const axiosRequestOrigin = (
  originalRequest: AxiosResponse,
  axiosInstance: AxiosInstance
) => {
  return new Promise((resolve) => {
    subscribeTokenRefresh((token) => {
      originalRequest.headers.Authorization = `Bearer ${token}`;
      resolve(axiosInstance(originalRequest));
    });
  });
};

const refreshTokenHandle = (
  originRequest: any,
  axiosInstance: AxiosInstance,
  isFetch = false
) => {
  const userStore = useUserStore();
  const rToken: string | null = userStore.refreshToken;

  if (rToken) {
    if (!isRefreshing) {
      isRefreshing = true;
      axios
        .post(`${urlWithoutCache}auth/refresh-tokens`, {
          refreshToken: rToken,
        })
        .then((tokenResponse) => {
          const { accessToken, refreshToken: newRefreshToken } =
            tokenResponse.data.data;
          userStore.setTokens({
            accessToken,
            refreshToken: newRefreshToken,
          });
          isRefreshing = false;
          onRefreshed(accessToken);
          subscribers = [];
        })
        .catch(() => {
          userStore.clear();
          toast(AppToaster, {
            type: toast.TYPE.ERROR,
            data: {
              title: "Error",
              description: "Unauthorized",
            },
          } as ToastOptions);

          router.push({ name: routesName.login });
        });
    }
    return isFetch
      ? fetchRequestOrigin(originRequest)
      : axiosRequestOrigin(originRequest, axiosInstance);
  } else {
    userStore.clear();
    router.push({ name: routesName.login });
    return Promise.reject(originRequest);
  }
};

fetchIntercept.register({
  request: (url, config) => {
    return [url, requestInterceptor(config)];
  },

  requestError: (error) => {
    return Promise.reject(error);
  },

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  response: async (response) => {
    if (response.status !== 401) {
      if (response.status === 503 && fetchInterceptCounter === 0) {
        const gameStore = useGameStore();
        gameStore.setApiAvailability(false);
        return makeRequestWithRetry(response.request, true);
      }
      return response;
    }

    return refreshTokenHandle(response.request, axiosWithCache, true);
  },

  responseError: (error) => {
    return Promise.reject(error);
  },
});

export { axiosWithCache, axiosNoCache };
